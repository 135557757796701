import React from 'react';
import { Box, Typography, Modal, Button, useTheme, useMediaQuery } from '@mui/material';
import { atom, useAtom } from 'jotai';
import useLocales from '@/locales/useLocales';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';

// Atom to manage the state of the confirm message modal
export const confirmMessageAtom = atom<{
  open: boolean;
  message: React.ReactNode;
}>({
  open: false,
  message: '',
});

// Confirmation Dialog Component
const ConfirmationDialog: React.FC<{ message: React.ReactNode; onClose: () => void }> = ({ message, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { translate } = useLocales();
  const [safeAreaInsetTop, setSafeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: isMobile ? 0 : 2, // Remove border radius on mobile for fullscreen
        boxShadow: isMobile ? 'none' : theme.shadows[5],
        padding: theme.spacing(isMobile ? 2 : 4),
        maxWidth: isMobile ? '100%' : 'md',
        width: isMobile ? '100%' : '90%',
        height: isMobile ? '100%' : 'auto', // Full height on mobile
        marginTop: isMobile ? `${safeAreaInsetTop}px` : '0',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute space between elements
        minHeight: isMobile ? '100%' : '300px', // Ensure minimum height for space distribution
      }}
    >
      <Typography variant={isMobile ? 'h5' : 'h4'} gutterBottom>
        {message}
      </Typography>
      <Box sx={{ mt: 'auto', pb: 6 }}>
        {' '}
        {/* Pushes the button to the bottom */}
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{
            px: isMobile ? 3 : 6,
            py: isMobile ? 1.5 : 2,
            width: '100%', // Full width for better mobile UX
            maxWidth: '300px', // Optional: limit the max width on larger screens
          }}
        >
          {translate('buttons.confirm')}
        </Button>
      </Box>
    </Box>
  );
};

// Fullscreen Confirm Message Component
const FullscreenConfirmMessage: React.FC = () => {
  const [confirmMessage, setConfirmMessage] = useAtom(confirmMessageAtom);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setConfirmMessage({ ...confirmMessage, open: false });
  };

  return (
    <Modal open={confirmMessage.open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          alignItems: isMobile ? 'flex-start' : 'center',
          justifyContent: 'center',
          height: '100vh',
          bgcolor: isMobile ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.5)',
          padding: isMobile ? 0 : theme.spacing(2),
          overflow: 'auto',
          // Fullscreen styles for mobile
          ...(isMobile && {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }),
        }}
      >
        <ConfirmationDialog message={confirmMessage.message} onClose={handleClose} />
      </Box>
    </Modal>
  );
};

export default FullscreenConfirmMessage;
