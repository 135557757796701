import { defaultSnackbarQueueAtom } from '@/src/atoms/_app.states';
import { useAtom, useSetAtom } from 'jotai';
import { createContext, useContext, ReactNode } from 'react';

import { useLocales } from '../locales';
import { tokenAtom, useAuth } from './AuthProvider';
import { AuthApi } from '../communication/api/authApi';
import { UploadApi } from '../communication/api/uploadApi';
import { UserApi } from '../communication/api/userApi';
import { PaymentApi } from '../communication/api/paymentApi';
import { AllPaysApi } from '../communication/api/allpaysPaymentApi';

export type ApiContext = {
  authApi: AuthApi;
  userApi: UserApi;
  uploadApi: UploadApi;
  paymentApi: PaymentApi;
  allPaysApi: AllPaysApi;
};

const ApiContext = createContext<ApiContext>({} as Partial<ApiContext> as ApiContext);
export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const setSnackbarQueue = useSetAtom(defaultSnackbarQueueAtom);
  const { translate } = useLocales();
  const [token, setToken] = useAtom(tokenAtom);

  const getAuthApi = () => new AuthApi(setSnackbarQueue, translate, token);
  const getUserApi = () => new UserApi(setSnackbarQueue, translate, token);
  const getUploadApi = () => new UploadApi(setSnackbarQueue, translate, token);
  const getPaymentApi = () => new PaymentApi(setSnackbarQueue, translate, token);
  const getAllPaysApi = () => new AllPaysApi(setSnackbarQueue, translate, token);
  return (
    <ApiContext.Provider
      value={{
        authApi: getAuthApi(),
        userApi: getUserApi(),
        uploadApi: getUploadApi(),
        paymentApi: getPaymentApi(),
        allPaysApi: getAllPaysApi(),
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
