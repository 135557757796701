import { m } from 'framer-motion';
import { styled, keyframes } from '@mui/material/styles';
import { Container, Stack, Box, Typography } from '@mui/material';
import { MotionViewport, varFade } from '../../components/animate';
import { useEffect, useState, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import useLocales from '@/locales/useLocales';
import Headline, { SubHeadline } from '@/components/text/headlines';
import useResponsive from '@/hooks/useResponsive';
import BigHomeButton from '@/components/buttons/homeMobile';
import { PATH_AUTH } from '@/routes/paths';
import { Capacitor } from '@capacitor/core';
import ReactPlayerComponent from './ReactPlayerComponent';
import IframeComponent from './IframeComponent';

// Gradient animation for background
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Styled component for background
const PortraitSetBackground = styled('div')({
  position: 'relative', // needed for the overlay
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  background: 'linear-gradient(to bottom right, #222 0%, #000000 100%)',
  backgroundSize: '400% 400%',
  animation: `${gradientAnimation} 15s ease infinite`,
  perspective: '1000px',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '-200px',
    right: '-200px',
    background: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateZ(-100px)',
  },
});

// Styled component for root
const StyledRoot = styled('div')(({ theme }) => ({
  // height: '100vh',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(20),
  },
}));

// Styled component for full-screen dimming overlay
const FullScreenOverlay = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'opacity 0.5s ease',
  '&.hidden': {
    opacity: 0,
    pointerEvents: 'none',
  },
  '&.visible': {
    opacity: 1,
    pointerEvents: 'auto',
  },
});

export default function ComponentHero() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'md');
  const isUnder400px = useResponsive('down', 400);
  const [headerOffset, setHeaderOffset] = useState(0);

  // Move Safari detection to useMemo with SSR check
  const isSafari = useMemo(() => {
    if (typeof window === 'undefined') return false;
    return /^((?!chrome|android).).*safari/i.test(navigator.userAgent);
  }, []);

  // Check if the app is running on iOS using Capacitor with SSR safety
  const isIOSApp = useMemo(() => {
    if (typeof window === 'undefined') return false;
    return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
  }, []);

  useEffect(() => {
    const src = isMobile
      ? 'https://customer-wpo4wdc07bb0hh2a.cloudflarestream.com/e00b5a7e0249dd766ed61a20f6c8ab85/manifest/video.m3u8'
      : 'https://customer-wpo4wdc07bb0hh2a.cloudflarestream.com/2c4ab25669955b5f4400733d5ccc7cbd/manifest/video.m3u8';
    setVideoSrc(src);
  }, [isMobile]);

  // Handle fullscreen enter event
  const handleFullscreenEnter = () => {
    // No action needed
  };

  // Handle fullscreen exit event
  const handleFullscreenExit = () => {
    // Reset player state or perform other actions
    setShowPlayer(false);
    setIsPlaying(false);
    setOverlayVisible(false);
  };
  const handleResetPlayer = () => {
    setShowPlayer(false);
    setIsPlaying(false);
    setOverlayVisible(false);
  };

  useEffect(() => {
    // Clean up event listeners on component unmount
    return () => {
      // No need to remove event listeners
    };
  }, []);

  // Handle play button click
  const handlePlayClick = () => {
    setShowPlayer(true);
    setIsPlaying(true);
    setOverlayVisible(true);
  };

  // Handle mouse enter event
  const handleMouseEnter = () => {
    setShowControls(true);
  };

  // Handle mouse leave event
  const handleMouseLeave = () => {
    setShowControls(false);
  };

  // Handle video end event
  const handleVideoEnd = () => {
    setShowPlayer(false);
    setIsPlaying(false);
    setOverlayVisible(false);
  };

  // Ensure document is defined before accessing it
  const headerHeight = typeof document !== 'undefined' ? document.getElementById('header-main')?.clientHeight : 0;
  const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

  useEffect(() => {
    const headerElement = document.getElementById('header-main');
    if (headerElement) {
      setHeaderOffset(headerElement.clientHeight);
    }
  }, []); // Run once on mount

  return (
    <PortraitSetBackground className="portrait-set-background">
      <div id="video" style={{ position: 'relative', top: `-${headerOffset}px` }} />
      <StyledRoot>
        <Container component={MotionViewport} sx={{ py: isMobile ? 6 : 0 }}>
          <Stack spacing={isDesktop ? 9 : isUnder400px ? 6 : 6}>
            <m.div variants={varFade().in}>
              <Headline text={translate('home.videoHeroTitle')} sx={{ textAlign: 'center' }} />
              <SubHeadline text={translate('home.videoHeroDescription')} />
            </m.div>

            <Box
              sx={
                isMobile
                  ? {
                      width: '100%',
                    }
                  : {
                      width: '100%',
                      paddingTop: '56.25%',
                      boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.2)',
                    }
              }
              position="relative"
              borderRadius={isMobile ? 3 : 6}
              overflow="hidden"
            >
              {isMobile ? (
                <IframeComponent />
              ) : showPlayer ? (
                <ReactPlayerComponent
                  videoSrc={videoSrc}
                  isPlaying={isPlaying}
                  onVideoEnd={handleVideoEnd}
                  onFullscreenExit={handleFullscreenExit}
                />
              ) : (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{ cursor: 'pointer' }}
                  onClick={handlePlayClick}
                >
                  <img
                    src="https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/ac7eb0f4-5782-44b5-892d-02ae6fb79b00/public"
                    alt="thumbnail"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={60}
                    height={60}
                    borderRadius="50%"
                    bgcolor="rgba(0, 0, 0, 0.6)"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 16 16">
                      <path
                        fill="currentColor"
                        d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M1.5 8a6.5 6.5 0 1 0 13 0a6.5 6.5 0 0 0-13 0m4.879-2.773l4.264 2.559a.25.25 0 0 1 0 .428l-4.264 2.559A.25.25 0 0 1 6 10.559V5.442a.25.25 0 0 1 .379-.215"
                      ></path>
                    </svg>
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>
          {innerHeight > 600 && isMobile && (
            <Stack spacing={3} sx={{ marginTop: 8, marginBottom: 4, textAlign: 'center' }}>
              {/* <BigHomeButton text={translate('buttons.free200')} url="#betaHome" /> */}
              <BigHomeButton text={translate('menu.signIn')} url={PATH_AUTH.login} />
            </Stack>
          )}
        </Container>
      </StyledRoot>

      {/* Full-screen overlay */}
      {overlayVisible && !isIOSApp && !isMobile && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(0, 0, 0, 0.8)"
          zIndex={9999}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleResetPlayer}
        />
      )}
    </PortraitSetBackground>
  );
}
