import React, { ReactNode } from 'react';
import { atom, useAtom } from 'jotai';
import dynamic from 'next/dynamic';

const DynamicBackdrop = dynamic(() => import('@mui/material/Backdrop'), { ssr: false });
const DynamicCircularProgress = dynamic(() => import('@mui/material/CircularProgress'), { ssr: false });

export const loadingBackdropAtom = atom(false);
export const loadingBackdropComponentAtom = atom<ReactNode>(<DynamicCircularProgress color="inherit" />);

interface LoadingBackdropProviderProps {
  children: ReactNode;
}

export const LoadingBackdropProvider: React.FC<LoadingBackdropProviderProps> = ({ children }) => {
  const [isOpen] = useAtom(loadingBackdropAtom);
  const [component] = useAtom(loadingBackdropComponentAtom);

  return (
    <>
      <DynamicBackdrop open={isOpen} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        {component}
      </DynamicBackdrop>
      {children}
    </>
  );
};
