import React, { useEffect, useState, useRef } from 'react';
import { Snackbar, Typography, Box, useTheme, Stack } from '@mui/material';
import { gsap } from 'gsap';
import Iconify from '../iconify';
import { formatNumber } from '@/utils/formatNumber';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';
import { useAtom } from 'jotai';

type CoinBalanceCountingSnackbarProps = {
  oldCoins: number;
  newCoins: number;
  oldTrainings: number;
  newTrainings: number;
};

const CoinBalanceCountingSnackbar: React.FC<CoinBalanceCountingSnackbarProps> = ({ oldCoins, newCoins, oldTrainings, newTrainings }) => {
  const [coins, setCoins] = useState(oldCoins);
  const [trainings, setTrainings] = useState(oldTrainings);
  const snackbarRef = useRef<HTMLDivElement>(null);
  const coinRef = useRef<HTMLSpanElement>(null);
  const trainingRef = useRef<HTMLSpanElement>(null);

  const theme = useTheme();

  // Flag to track the first render
  const isFirstRender = useRef(true);

  useEffect(() => {
    const steps = 30; // Number of steps for the counting animation
    const coinStep = Math.max(1, Math.abs(newCoins - oldCoins) / steps);
    const trainingStep = Math.max(1, Math.abs(newTrainings - oldTrainings) / steps);

    let coinInterval: NodeJS.Timeout | undefined;
    let trainingInterval: NodeJS.Timeout | undefined;

    if (oldCoins !== newCoins) {
      coinInterval = setInterval(() => {
        setCoins((prev) => {
          const next = prev < newCoins ? Math.min(prev + coinStep, newCoins) : Math.max(prev - coinStep, newCoins);
          if (next === newCoins && coinInterval) clearInterval(coinInterval);
          return next;
        });
      }, 50); // Faster steps for a more engaging effect
    }

    if (oldTrainings !== newTrainings) {
      trainingInterval = setInterval(() => {
        setTrainings((prev) => {
          const next = prev < newTrainings ? Math.min(prev + trainingStep, newTrainings) : Math.max(prev - trainingStep, newTrainings);
          if (next === newTrainings && trainingInterval) clearInterval(trainingInterval);
          return next;
        });
      }, 50); // Faster steps for a more engaging effect
    }

    // Cleanup function to clear intervals
    return () => {
      if (coinInterval) clearInterval(coinInterval);
      if (trainingInterval) clearInterval(trainingInterval);
    };
  }, [newCoins, newTrainings, oldCoins, oldTrainings]);

  // GSAP animations for initial entrance
  useEffect(() => {
    if (isFirstRender.current && snackbarRef.current) {
      gsap.fromTo(
        snackbarRef.current,
        { y: -50, opacity: 0, visibility: 'hidden' },
        { y: 0, opacity: 1, visibility: 'visible', duration: 0.5, ease: 'back.out(1.7)' }
      );

      isFirstRender.current = false;
    }
  }, []);

  const [safeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ref={snackbarRef}
      style={{
        position: 'fixed',
        marginTop: `${safeAreaInsetTop}px`,
        top: theme.spacing(0.6),
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'rgba(0, 0, 0, 0.9)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
        borderRadius: theme.spacing(1.5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease, visibility 0.3s ease',
      }}
      className="customSnackbar"
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          p: theme.spacing(1.5),
        }}
      >
        {oldCoins !== newCoins && (
          <>
            <Iconify icon="ic:round-monetization-on" width={24} height={24} />
            <Typography
              variant="body1"
              ref={coinRef}
              style={{
                display: 'inline-block',
                transition: 'transform 0.1s ease-in-out, opacity 0.1s ease-in-out',
                transform: 'translateY(0)',
                opacity: 1,
                color: '#fff',
                marginLeft: theme.spacing(1),
                paddingRight: oldTrainings !== newTrainings ? theme.spacing(2) : 0,
              }}
            >
              {parseFloat(coins.toFixed(1)).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}
            </Typography>
          </>
        )}
        {oldTrainings !== newTrainings && (
          <>
            <Iconify icon="ic:round-school" width={24} height={24} />
            <Typography
              variant="body1"
              ref={trainingRef}
              style={{
                display: 'inline-block',
                transition: 'transform 0.1s ease-in-out, opacity 0.1s ease-in-out',
                transform: 'translateY(0)',
                opacity: 1,
                color: '#fff',
                marginLeft: theme.spacing(1),
              }}
            >
              {formatNumber(Number(trainings.toFixed(0)))}
            </Typography>
          </>
        )}
      </Stack>
    </Snackbar>
  );
};

export default CoinBalanceCountingSnackbar;
