import { useEffect, useRef, useState } from 'react';
import styles from './MagicWand.module.css';
import React from 'react';
import { gsap } from 'gsap';
import useLocales from '@/locales/useLocales';
import { fetchDataFromBackend } from '@/src/communication/socket/dataRequest';
import { Gender, SocketEmits } from '@api-types';
import { PortraitSetWithLatestPictureViewModel } from '@models/portraitSetTypes';
import { useAtomValue } from 'jotai';
import { socketAtom } from '@/providers/WebSocketProvider';
import { useRouter } from 'next/router';
import { usePageVisibility } from '@/hooks/usePageVisibility';
import dynamic from 'next/dynamic';

const addCdnParams = (url: string) => {
  if (!url) return url;

  // Check if URL already has CDN parameters
  if (url.includes('/cdn-cgi/image/')) {
    return url;
  }

  // Split URL into domain and path
  const urlObj = new URL(url);
  return `${urlObj.origin}/cdn-cgi/image/width=600,quality=75${urlObj.pathname}`;
};

const getRandomBalancedSets = (
  portraitSets: PortraitSetWithLatestPictureViewModel[],
  count: number,
  categories?: string[]
): PortraitSetWithLatestPictureViewModel[] => {
  // Filter by categories if provided, otherwise just exclude hairstyles
  const validSets = portraitSets.filter((set) => {
    const hasValidPicture = set?.latestPicture?.pictureThumbUrl;
    if (!hasValidPicture) return false;

    // Add CDN parameters to the URL
    set.latestPicture!.pictureThumbUrl = addCdnParams(set.latestPicture!.pictureThumbUrl);

    if (categories?.length) {
      return categories.some((cat) => set.category?.includes(cat));
    }
    return !set.category?.includes('hairstyles');
  });

  // Return empty array if no valid sets
  if (validSets.length === 0) return [];

  // Shuffle and get desired count
  return [...validSets].sort(() => Math.random() - 0.5).slice(0, count);
};

const getGridCount = () => {
  const isMobilePhone = window.innerWidth <= 500;
  return isMobilePhone ? 2 : window.innerWidth <= 800 ? 3 : 4;
};

interface MagicWandProps {
  categories?: string[];
}

const ClientMagicWand = ({ categories }: MagicWandProps) => {
  const tilesRef = useRef<HTMLDivElement>(null);
  const [initialSets, setInitialSets] = useState<PortraitSetWithLatestPictureViewModel[]>([]);
  const [allPortraitSets, setAllPortraitSets] = useState<PortraitSetWithLatestPictureViewModel[]>([]);
  const timeoutsRef = useRef<number[]>([]);
  const socket = useAtomValue(socketAtom);
  const isPageVisible = usePageVisibility();
  const animationsRef = useRef<gsap.core.Timeline[]>([]);

  useEffect(() => {
    const fetchPortraitSets = async () => {
      if (socket) {
        try {
          const portraitSets = await fetchDataFromBackend<PortraitSetWithLatestPictureViewModel[]>(
            socket,
            SocketEmits.GetPreviewPicturesByGender,
            {}
          );

          const validSets = portraitSets.filter((set) => set?.latestPicture?.pictureThumbUrl);
          setAllPortraitSets(validSets);

          const tileCount = getGridCount();
          const initialBalancedSets = getRandomBalancedSets(validSets, tileCount, categories);

          if (initialBalancedSets.length > 0) {
            setInitialSets(initialBalancedSets);
          }
        } catch (error) {
          console.error('Error fetching portrait sets:', error);
        }
      }
    };

    fetchPortraitSets();
  }, [socket, categories]);

  useEffect(() => {
    if (!allPortraitSets.length || !isPageVisible) return;

    const tileCount = getGridCount();

    const updateImage = (index: number) => {
      const imgContainer = tilesRef.current?.children[index] as HTMLElement;
      if (imgContainer) {
        const oldImg = imgContainer.querySelector('img') as HTMLImageElement;
        const currentImages = Array.from(tilesRef.current?.querySelectorAll('img') || []).map((img) => img.src);

        // Get new balanced set and find one that's not currently displayed
        let newSets = getRandomBalancedSets(allPortraitSets, tileCount, categories);
        let newSet;
        do {
          newSet = newSets[Math.floor(Math.random() * newSets.length)];
        } while (currentImages.includes(newSet?.latestPicture?.pictureThumbUrl || ''));

        const newImg = document.createElement('img');
        newImg.src = newSet?.latestPicture?.pictureThumbUrl || '';
        newImg.style.position = 'absolute';
        newImg.style.top = '0';
        newImg.style.left = '0';
        newImg.style.width = '100%';
        newImg.style.height = '100%';
        newImg.style.objectFit = 'cover';
        newImg.style.opacity = '0';

        imgContainer.appendChild(newImg);

        // Create a background div for the "hole" effect
        const bgDiv = document.createElement('div');
        bgDiv.style.position = 'absolute';
        bgDiv.style.top = '0';
        bgDiv.style.left = '0';
        bgDiv.style.width = '100%';
        bgDiv.style.height = '100%';
        bgDiv.style.background = 'radial-gradient(circle at center, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 70%)';
        bgDiv.style.borderRadius = '3vmin';
        bgDiv.style.opacity = '0';
        imgContainer.appendChild(bgDiv);

        // Store the timeline reference
        const tl = gsap.timeline();
        animationsRef.current[index] = tl;

        tl.to(oldImg, { opacity: 0, duration: 0.3 }, 0)
          .to(bgDiv, { opacity: 1, duration: 0.3 }, 0)
          .to(bgDiv, {
            background: 'radial-gradient(circle at center, rgba(255,255,255,0.8) 100%, rgba(0,0,0,0) 100%)',
            duration: 0.3,
            ease: 'power2.in',
          })
          .to(newImg, { opacity: 1, duration: 0.3 })
          .to(bgDiv, { opacity: 0, duration: 0.3 }, '-=0.3')
          .call(() => {
            oldImg.remove();
            bgDiv.remove();
          });
      }
    };

    const startUpdating = (index: number) => {
      if (!isPageVisible) return;

      const randomTime = Math.random() * (7000 - 3000) + 3000;
      const timeout = window.setTimeout(() => {
        updateImage(index);
        startUpdating(index);
      }, randomTime);
      timeoutsRef.current[index] = timeout;
    };

    Array.from({ length: tileCount }).forEach((_, index) => startUpdating(index));

    return () => {
      // Clear all timeouts and animations when unmounting or visibility changes
      timeoutsRef.current.forEach((timeout) => clearTimeout(timeout));
      animationsRef.current.forEach((timeline) => timeline?.kill());
    };
  }, [allPortraitSets, categories, isPageVisible]);

  const { translate } = useLocales();

  if (!initialSets.length) {
    return (
      <div className={styles['tiles']}>
        {Array.from({ length: getGridCount() }).map((_, index) => (
          <div
            key={index}
            className={`${styles['tile']} tile`}
            style={{
              transition: 'all 0.3s ease-in-out',
              overflow: 'hidden',
              position: 'relative',
              background: '#f0f0f0',
              animation: 'pulse 1.5s infinite ease-in-out',
            }}
          />
        ))}
      </div>
    );
  }

  return (
    <div id="tiles" className={styles['tiles']} ref={tilesRef}>
      {initialSets.map((set, index) => (
        <div
          key={index}
          className={`${styles['tile']} tile`}
          style={{ transition: 'all 0.3s ease-in-out', overflow: 'hidden', position: 'relative' }}
        >
          {set?.latestPicture?.pictureThumbUrl && (
            <img src={set.latestPicture.pictureThumbUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          )}
          <div
            style={{
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              background: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              padding: '4px 8px',
              borderRadius: '3vmin',
              fontSize: '12px',
              fontWeight: '500',
              letterSpacing: '0.5px',
              backdropFilter: 'blur(4px)',
              textTransform: 'uppercase',
              zIndex: 10,
            }}
          >
            {translate('AIImageComparison.generated')}
          </div>
        </div>
      ))}
    </div>
  );
};

// Export a dynamic component with SSR disabled
export default dynamic(() => Promise.resolve(ClientMagicWand), {
  ssr: false,
});
