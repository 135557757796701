import { isBrowser } from '../utils/isBrowser';

export interface AuthTokens {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresAt: string;
  refreshTokenExpiresAt: string;
}

const AUTH_TOKENS_KEY = 'authTokens';

export const authStorage = {
  getTokens: (): AuthTokens | null => {
    if (!isBrowser()) return null;

    try {
      const tokens = localStorage.getItem(AUTH_TOKENS_KEY);
      return tokens ? JSON.parse(tokens) : null;
    } catch {
      return null;
    }
  },

  setTokens: (tokens: AuthTokens): void => {
    if (!isBrowser()) return;

    localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(tokens));
  },

  clearTokens: (): void => {
    if (!isBrowser()) return;

    localStorage.removeItem(AUTH_TOKENS_KEY);
  },

  isTokenValid: (token: string | null): boolean => {
    if (!token) return false;

    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      return Date.now() < exp * 1000;
    } catch {
      return false;
    }
  },
};
