import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fCurrency(number: number) {
  const format = number ? numeral(number).format('$0,0.00') : '';

  return result(format, '.00');
}

export function fPercent(number: number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number: number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function formatNumberForBadge(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num;
  }
}
export function fData(number: number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key: string = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function formatNumber(value: number): string {
  const parts = value.toFixed(2).split('.');

  // Format the integer part with dots as thousand separators
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Check if the decimal part is '00'
  if (parts[1] === '00') {
    return integerPart;
  }

  // Append the decimal part with a comma
  return `${integerPart},${parts[1]}`;
}
