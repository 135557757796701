import React, { useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';

interface ReactPlayerComponentProps {
  videoSrc: string;
  isPlaying: boolean;
  onVideoEnd: () => void;
  onFullscreenExit: () => void;
}

const ReactPlayerComponent: React.FC<ReactPlayerComponentProps> = ({ videoSrc, isPlaying, onVideoEnd, onFullscreenExit }) => {
  const playerRef = useRef<ReactPlayer>(null);

  return (
    <ReactPlayer
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 10000 }}
      ref={playerRef}
      url={videoSrc}
      width="100%"
      height="100%"
      playing={isPlaying}
      controls={true}
      onEnded={onVideoEnd}
      playsinline={true}
      onFullscreenPlayerWillDismiss={onFullscreenExit}
      onFullscreenPlayerDidDismiss={onFullscreenExit}
    />
  );
};

export default ReactPlayerComponent;
