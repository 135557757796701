import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { changeLanguage as i18nChangeLanguage } from './i18n';
import { allLangs, defaultLang } from './config-lang';
import { UserApi } from '../communication/api/userApi';

let isLanguageInitialized = false;

export const initializeLanguage = async (userApi: UserApi) => {
  if (isLanguageInitialized) {
    return;
  }

  let newLang;

  // 1. Check for Capacitor environment (mobile app)
  if (Capacitor.isNativePlatform()) {
    try {
      const { value } = await Device.getLanguageCode();
      const supportedLang = allLangs.find((lang) => lang.value === value);
      newLang = supportedLang ? value : undefined;
    } catch (deviceError) {
      console.error('Failed to get device language:', deviceError);
    }
  }

  // 2. Try the backend if needed
  if (!newLang) {
    try {
      const response = await userApi.getUserLanguage();
      newLang = response.language;
    } catch (error) {
      console.error('Failed to fetch user language from backend:', error);
    }
  }

  // 3. Fallback to default language
  if (!newLang) {
    newLang = defaultLang.value;
  }

  await i18nChangeLanguage(newLang);
  localStorage.setItem('i18nextLng', newLang); // Update localStorage
  isLanguageInitialized = true;
};
