import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from '@/components/snackbar';
import { defaultSnackbarQueueAtom, countingSnackbarQueueAtom } from '@/src/atoms/_app.states';
import CoinBalanceCountingSnackbar from './CoinBalanceCountingSnackbar';

// Define CountingSnack type for better type safety
interface CountingSnack {
  oldCoins?: number;
  newCoins?: number;
  oldTrainings?: number;
  newTrainings?: number;
}

const GlobalSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [defaultSnackbarQueue, setDefaultSnackbarQueue] = useAtom(defaultSnackbarQueueAtom);
  const [countingSnackbarQueue, setCountingSnackbarQueue] = useAtom(countingSnackbarQueueAtom);
  const processedDefaultSnacks = useRef(new Set<string>());
  const processedCountingSnacks = useRef(new Set<string>());
  const [currentCountingSnack, setCurrentCountingSnack] = useState<CountingSnack | null>(null);

  useEffect(() => {
    // Process all default messages in the queue
    defaultSnackbarQueue.forEach((snack, index) => {
      const { message, variant } = snack;
      if (!message) return;

      // Create a unique key for each snack
      const snackKey = `${message}-${index}`;

      // Check if the snack has already been processed
      if (processedDefaultSnacks.current.has(snackKey)) return;

      processedDefaultSnacks.current.add(snackKey);

      enqueueSnackbar(message, {
        variant,
        onExited: () => {
          // Remove the message from the queue after it's displayed
          setDefaultSnackbarQueue((currentQueue) => currentQueue.filter((_, i) => i !== index));
          // Remove the snack from the processed set
          processedDefaultSnacks.current.delete(snackKey);
        },
      });
    });
  }, [defaultSnackbarQueue, enqueueSnackbar, setDefaultSnackbarQueue]);

  useEffect(() => {
    // Holds the timeout instance for resetting
    let timeoutId: NodeJS.Timeout | null = null;

    // Process all counting messages in the queue
    countingSnackbarQueue.forEach((snack, index) => {
      const { oldCoins, newCoins, oldTrainings, newTrainings } = snack;
      if (!oldCoins && !newCoins && !oldTrainings && !newTrainings) return;

      // Create a unique key for each snack
      const snackKey = `${oldCoins}-${newCoins}-${oldTrainings}-${newTrainings}-${index}`;

      // Check if the snack has already been processed
      if (processedCountingSnacks.current.has(snackKey)) return;

      // Add to the set of processed snacks
      processedCountingSnacks.current.add(snackKey);

      // Display the custom coin counting snackbar
      setCurrentCountingSnack({ oldCoins, newCoins, oldTrainings, newTrainings });

      // Clear existing timeout if any, to reset the timer
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Setting a timeout to remove this current counting snack after a certain duration
      timeoutId = setTimeout(() => {
        setCurrentCountingSnack(null);
        setCountingSnackbarQueue([]);
        processedCountingSnacks.current.clear();
      }, 5000); // Adjust this timeout based on the duration you want the snackbar to be displayed
    });

    // Cleanup function to clear timeout when the component unmounts or dependencies change
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [countingSnackbarQueue, setCountingSnackbarQueue]);

  return (
    <>
      {/* Render custom snackbar for coin counting */}
      {currentCountingSnack && (
        <CoinBalanceCountingSnackbar
          oldCoins={currentCountingSnack.oldCoins!}
          newCoins={currentCountingSnack.newCoins!}
          oldTrainings={currentCountingSnack.oldTrainings!}
          newTrainings={currentCountingSnack.newTrainings!}
        />
      )}
    </>
  );
};

export default GlobalSnackbar;
