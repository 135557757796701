import { BaseApi, TranslationMapping } from './_baseApi';

export class AllPaysApi extends BaseApi {
  constructor(setSnackbarQueue: any, translate: (text: any, options?: any) => string, token: string | null) {
    super(setSnackbarQueue, translate, token);
  }

  public createAllPaysPaymentLink = async (userId: number, packageId: number): Promise<string> => {
    const codeMappings: TranslationMapping = {
      400: 'payment.errors.invalidData',
      404: 'payment.errors.packageNotFound',
      default: 'payment.errors.creationFailed',
    };

    const response = await this.post<{ paymentLink: string }>('/allpays/createPaymentLink', { userId, packageId }, codeMappings);

    return response.paymentLink;
  };
}
