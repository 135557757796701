import { useState, useEffect, useRef } from 'react';
import { Box, Container, styled, Typography, useTheme, useMediaQuery } from '@mui/material';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from '@/components/animate';
import useLocales from '@/locales/useLocales';
import { PATH_AUTH } from '@/routes/paths';
import BigHomeButton from '@/components/buttons/homeMobile';

interface ShowcaseImage {
  url: string;
}

const showcaseImages: ShowcaseImage[] = [
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/d6ffacff-edb3-4598-0eba-dc65cc056000/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/f9cbf807-b0ed-4899-e2d1-33aa4423c700/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/ce2f5018-dcea-446e-8d06-a81164605700/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/611b1bb3-bf74-4014-2cf3-33f38e807a00/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/8e69e85d-6a34-48b3-7736-1e826bc10600/widejeedoo',
  },
  // Add more AI-generated showcase images
];

const StyledShowcaseSection = styled('div')<{ backgroundImage: string }>(({ theme, backgroundImage }) => ({
  position: 'relative',
  padding: theme.spacing(8, 0),
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '80vh',
  transition: 'background-image 1.5s ease-in-out',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const TextBox = styled(m(Box))(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  right: theme.spacing(12),
  bottom: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.common.white,
  textRendering: 'optimizeLegibility',
  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  transformOrigin: 'bottom right',
  transition: 'all 0.5s ease-in-out',
  maxWidth: '80%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '40%',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '31%',
  },
}));

export default function AIGenerationShowcase() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const { translate } = useLocales();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const preloadedIndices = useRef<Set<number>>(new Set());

  const preloadImage = (index: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (preloadedIndices.current.has(index)) {
        resolve();
        return;
      }
      const img = new Image();
      img.src = showcaseImages[index].url;
      img.onload = () => {
        preloadedIndices.current.add(index);
        resolve();
      };
      img.onerror = reject;
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const handleChangeImage = async () => {
      const nextIndex = (currentIndex + 1) % showcaseImages.length;
      try {
        await preloadImage(nextIndex);
        setCurrentIndex(nextIndex);
      } catch (error) {
        console.error('Failed to preload image:', error);
        setCurrentIndex(nextIndex);
      }
    };

    const intervalId = setInterval(handleChangeImage, 3000);
    return () => clearInterval(intervalId);
  }, [currentIndex, isVisible]);

  const currentImage = showcaseImages[currentIndex];

  return (
    <StyledShowcaseSection ref={sectionRef} backgroundImage={isVisible ? currentImage.url : ''}>
      <Container component={MotionViewport}>
        <TextBox>
          <m.div variants={varFade().inDown}>
            <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
              {translate('AIGeneration.title')}
            </Typography>
          </m.div>
          <m.div variants={varFade().inUp}>
            <Typography variant="h5" sx={{ fontWeight: 'normal', mt: 1 }}>
              {translate('AIGeneration.description')}
            </Typography>
          </m.div>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <BigHomeButton
              text={translate('AIGeneration.startNow')}
              url={PATH_AUTH.register}
              fullWidth={isMobile}
              sx={{
                py: 2.5,
                px: 6,
                fontSize: isMobile ? '1rem' : '1.5rem',
                backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
                marginTop: theme.spacing(-3),
              }}
            />
          </Box>
        </TextBox>
      </Container>
    </StyledShowcaseSection>
  );
}
