// @mui
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import { isIOS } from 'react-device-detect';

// ----------------------------------------------------------------------

export default function StyledNotistack() {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  const inputGlobalStyles = (
    <GlobalStyles
      styles={{
        '#__next': {
          '.SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows?.z8 || '0px 8px 16px 0px rgba(0,0,0,0.2)',
            borderRadius: theme.shape.borderRadius,
            color: isLight ? theme.palette.common.white : theme.palette.grey[800],
            backgroundColor: isLight ? theme.palette.grey[900] : theme.palette.common.white,
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '.SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '.SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,

            '& svg': {
              width: 20,
              height: 20,
            },
          },
        },
        ...(isIOS && {
          '*': {
            WebkitTouchCallout: 'none',
            userSelect: 'none',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
          },
        }),
      }}
    />
  );

  return inputGlobalStyles;
}
