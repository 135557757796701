import { atom, useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useSettingsContext } from '../components/settings';
import { allLangs } from './config-lang';
import { Link } from '@mui/material';
import NextLink from 'next/link';
import { useEffect, useState, useCallback } from 'react';
import { changeLanguage as i18nChangeLanguage } from './i18n';
import { useRouter } from 'next/router';

export const currentLanguageAtom = atom<string>('en');

const isClient = typeof window !== 'undefined';

export const useLocales = <T = any,>(baseFilePath?: string) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const { onChangeDirectionByLang } = useSettingsContext();
  const [currentLangValue, setCurrentLangValue] = useState<string | null>(null);
  const currentLang = allLangs.find((_lang) => _lang.value === currentLangValue) || null;

  const [dataModule, setDataModule] = useState<T | null>(null);
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const setCurrentLanguage = useSetAtom(currentLanguageAtom);

  const handleChangeLanguage = useCallback(
    async (newLanguage: string | undefined) => {
      if (newLanguage && newLanguage !== currentLangValue && isClient) {
        await i18nChangeLanguage(newLanguage);
        localStorage.setItem('i18nextLng', newLanguage); // Update localStorage
        setCurrentLangValue(newLanguage); // Update state
        setCurrentLanguage(newLanguage); // Update Jotai atom
        onChangeDirectionByLang(newLanguage); // Update theme direction
        setIsLanguageLoaded(false); // Reset language loaded state

        // Add URL update while keeping existing functionality
        const { pathname, asPath, query } = router;
        await router.push(
          {
            pathname,
            query: { ...query, lang: newLanguage },
          },
          asPath,
          { shallow: true }
        );
      }
    },
    [currentLangValue, onChangeDirectionByLang, router]
  );

  const storedLang = isClient ? localStorage.getItem('i18nextLng') : null;

  useEffect(() => {
    const urlLang = router.query.lang as string;

    if (urlLang && allLangs.some((lang) => lang.value === urlLang)) {
      // If URL has valid language parameter, use it
      setCurrentLangValue(urlLang);
      setCurrentLanguage(urlLang);
      i18nChangeLanguage(urlLang);
      onChangeDirectionByLang(urlLang);
      if (isClient && storedLang !== urlLang) {
        localStorage.setItem('i18nextLng', urlLang);
      }
    } else if (storedLang) {
      // Fallback to stored language if no URL parameter
      setCurrentLangValue(storedLang);
      setCurrentLanguage(storedLang);
      i18nChangeLanguage(storedLang);
      onChangeDirectionByLang(storedLang);
    }
  }, [router.query.lang, storedLang]);

  useEffect(() => {
    if (!currentLangValue) return;
    const loadLanguageData = async () => {
      if (baseFilePath && currentLangValue) {
        try {
          const importedModule = await import(`../${baseFilePath}_${currentLangValue}.ts`);
          setDataModule(importedModule as T);
          setIsLanguageLoaded(true); // Set language loaded to true after data is loaded
        } catch (error) {
          console.error(`Failed to load data for language: ${currentLangValue}`, error);
        }
      }
    };

    if (currentLangValue) {
      loadLanguageData();
    }
  }, [currentLangValue, baseFilePath, storedLang]);

  const interpolateLinks = (text: any, links: string[] = []) => {
    return text.split(/({{.*?}})/g).map((segment: string) => {
      if (segment.startsWith('{{') && segment.endsWith('}}')) {
        const linkUrl = links.shift();
        return linkUrl ? (
          <Link href={linkUrl} component={NextLink}>
            {segment.slice(2, -2)}
          </Link>
        ) : (
          segment
        );
      } else {
        return segment;
      }
    });
  };

  const translate = (text: any, options?: any) => {
    const translatedText = i18n.t(text!, options).toString();

    if (Array.isArray(options)) {
      return interpolateLinks(translatedText, options);
    }

    return translatedText;
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    interpolateLinks,
    currentLang,
    allLangs,
    dataModule,
    isLanguageLoaded, // Expose the loading state
  };
};

export default useLocales;
