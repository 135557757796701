import React, { createContext, useContext, useState, useEffect } from 'react';

interface CurrencyContextType {
  selectedCurrency: {
    name: string;
    symbol: string;
    id: number;
  };
  setSelectedCurrency: (currency: { name: string; symbol: string; id: number }) => void;
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(undefined);

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};

export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState({ name: 'USD', symbol: '$', id: 1 });

  useEffect(() => {
    const setUserCurrency = async () => {
      try {
        // Use browser language as fallback if country-based setting is not applicable
        const browserLanguage = navigator.language.toLowerCase();
        switch (browserLanguage) {
          case 'de':
          case 'fr':
          case 'es':
          case 'it':
          case 'pt':
          case 'nl':
            setSelectedCurrency({ name: 'EUR', symbol: '€', id: 2 });
            break;
          case 'fa':
            setSelectedCurrency({ name: 'IRR', symbol: '﷼', id: 4 });
            break;
          default:
            setSelectedCurrency({ name: 'EUR', symbol: '€', id: 2 }); // Default to EUR
        }
      } catch (error) {
        console.error('Error setting currency:', error);
        setSelectedCurrency({ name: 'EUR', symbol: '€', id: 2 }); // Default to EUR on error
      }
    };

    setUserCurrency();
  }, []);

  return <CurrencyContext.Provider value={{ selectedCurrency, setSelectedCurrency }}>{children}</CurrencyContext.Provider>;
};
