import { useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Grid } from '@mui/material';
import { m } from 'framer-motion';
import useLocales from '@/locales/useLocales';
import { MotionViewport, varFade } from '@/components/animate';
import styles from './AIShortVideoShowcase.module.css';
import { useState, useEffect, useRef } from 'react';
import VideoModal from '@/sections/dashboard/videos/VideoModal';
import { PATH_AUTH } from '@/routes/paths';
import LuxuryButton from '@/components/buttons/LuxuryButton';

function useElementOnScreen(options?: IntersectionObserverInit) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [options]);

  return { containerRef, isVisible };
}

export default function AIShortVideoShowcase() {
  const theme = useTheme();
  const { translate } = useLocales();

  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const videos = [
    'https://videos.myportrait.studio/5/43-5-197314.mp4',
    'https://videos.myportrait.studio/5/39-5-221858.mp4',
    'https://videos.myportrait.studio/5/44-5-202932.mp4',
    'https://videos.myportrait.studio/5/9-5-187473.mp4',
  ];

  const moreVideos = [
    'https://videos.myportrait.studio/1/85-1-222168.mp4',
    'https://videos.myportrait.studio/1/76-1-201091.mp4',
    'https://videos.myportrait.studio/1/79-1-200794.mp4',
    'https://videos.myportrait.studio/1/87-1-85040.mp4',
  ];

  const [videoStates, setVideoStates] = useState<{ [key: string]: { initialized: boolean; loaded: boolean } }>({});

  const handleVideoClick = (videoSrc: string) => {
    setSelectedVideo(videoSrc);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  const { containerRef: firstGridRef, isVisible: isFirstGridVisible } = useElementOnScreen({
    threshold: 0.1,
    rootMargin: '50px',
  });

  const { containerRef: secondGridRef, isVisible: isSecondGridVisible } = useElementOnScreen({
    threshold: 0.1,
    rootMargin: '50px',
  });

  useEffect(() => {
    if (isFirstGridVisible) {
      videos.forEach((video) => {
        setVideoStates((prev) => ({
          ...prev,
          [video]: { initialized: true, loaded: prev[video]?.loaded || false },
        }));
      });
    }
    if (isSecondGridVisible) {
      moreVideos.forEach((video) => {
        setVideoStates((prev) => ({
          ...prev,
          [video]: { initialized: true, loaded: prev[video]?.loaded || false },
        }));
      });
    }
  }, [isFirstGridVisible, isSecondGridVisible]);

  const handleVideoLoad = (videoSrc: string) => {
    setVideoStates((prev) => ({
      ...prev,
      [videoSrc]: { ...prev[videoSrc], loaded: true },
    }));
  };

  return (
    <>
      <div className={styles.neonBackground}>
        <div className={styles.lavaBackground}>
          <span></span>
        </div>
        <div className={styles.glowingOverlay}>
          <span></span>
        </div>
        <Container component={MotionViewport} sx={{ position: 'relative', zIndex: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <m.div variants={varFade().inDown}>
              <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'inherit' }}>
                {translate('AIShortVideoShowcase.experienceTheFuture')}
              </Typography>
            </m.div>
            <m.div variants={varFade().inDown}>
              <Typography variant="h5" sx={{ mt: 2, color: 'inherit' }}>
                {translate('AIShortVideoShowcase.transformYourPortraits')}
              </Typography>
            </m.div>
          </Box>

          <Grid container spacing={2} ref={firstGridRef}>
            {videos.map((videoSrc, index) => (
              <Grid item xs={6} sm={6} md={3} key={index}>
                <m.div variants={varFade().inUp}>
                  <div
                    className={styles.videoBox}
                    style={{
                      borderRadius: theme.shape.borderRadius * 2,
                      boxShadow: `0 0 20px ${theme.palette.background.default}`,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleVideoClick(videoSrc)}
                  >
                    <video
                      src={videoStates[videoSrc]?.initialized ? videoSrc : undefined}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className={videoStates[videoSrc]?.loaded ? styles.loaded : ''}
                      onLoadedData={() => handleVideoLoad(videoSrc)}
                    />
                  </div>
                </m.div>
              </Grid>
            ))}
          </Grid>

          <VideoModal open={Boolean(selectedVideo)} onClose={handleCloseModal} videoUrl={selectedVideo || ''} />

          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <m.div variants={varFade().inUp}>
              <Typography
                variant="body1"
                sx={{
                  mt: 2,
                  color: 'inherit',
                  maxWidth: '800px',
                  margin: '0 auto',
                  fontSize: '1.1rem',
                  lineHeight: 1.6,
                }}
              >
                {translate('AIShortVideoShowcase.explanation')}
              </Typography>
            </m.div>
          </Box>

          <Grid container spacing={2} sx={{ mt: 8 }} ref={secondGridRef}>
            {moreVideos.map((videoSrc, index) => (
              <Grid item xs={6} sm={6} md={3} key={index}>
                <m.div variants={varFade().inUp}>
                  <div
                    className={styles.videoBox}
                    style={{
                      borderRadius: theme.shape.borderRadius * 2,
                      boxShadow: `0 0 20px ${theme.palette.background.default}`,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleVideoClick(videoSrc)}
                  >
                    <video
                      src={videoStates[videoSrc]?.initialized ? videoSrc : undefined}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className={videoStates[videoSrc]?.loaded ? styles.loaded : ''}
                      onLoadedData={() => handleVideoLoad(videoSrc)}
                    />
                  </div>
                </m.div>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: 'center', mt: 8, mb: 4 }}>
            <m.div variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3, color: 'inherit' }}>
                {translate('AIShortVideoShowcase.readyToStart')}
              </Typography>
              <Typography variant="h6" sx={{ mb: 4, color: 'inherit', opacity: 0.87 }}>
                {translate('AIShortVideoShowcase.createYourOwn')}
              </Typography>
              <m.div variants={varFade().inUp}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LuxuryButton
                    text={translate('AIShortVideoShowcase.startCreating')}
                    url={PATH_AUTH.register}
                    color="warning"
                    sx={{ display: 'inline-flex', width: 'fit-content' }}
                  />
                </Box>
              </m.div>
            </m.div>
          </Box>
        </Container>
      </div>
    </>
  );
}
