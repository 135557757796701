import { styled } from '@mui/material/styles';

import MainLayout from '@/layouts/main';

import UseCaseSection from './UseCaseSection';

LandingPageNormal.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

const StyledRoot = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(12),
  // paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.background.default,
}));

export default function LandingPageNormal() {
  return (
    <>
      <StyledRoot>
        {/* Hero Section */}
        <UseCaseSection />
      </StyledRoot>
    </>
  );
}
