import { useScroll } from 'framer-motion';
import { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import styles from './HomeHero.module.css';
import { isIOS } from 'react-device-detect';
import { cssVariables } from './HomeHeroCssVariables';
import MagicWand from './MagicWand';
import { useAtom } from 'jotai';
import useLocales from '@/locales/useLocales';
import Headline from '@/components/text/headlines';
import { PATH_AUTH } from '@/routes/paths';
import { gsap } from 'gsap';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';
import Iconify from '@/components/iconify';
import LuxuryButton from '@/components/buttons/LuxuryButton';
import { useRouter } from 'next/router';
// import Stamp from '@/components/Stamp/Stamp';

const StyledRoot = styled('div')(({ theme }) => ({
  // position: 'fixed',
  minHeight: '100vh', // Set minimum height to 100vh
  display: 'flex', // Use flexbox for vertical alignment
  flexDirection: 'column', // Stack content vertically
  justifyContent: 'center', // Center content vertically
  transition: 'all 0.5s ease-in-out', // Add smooth transition for all properties
  pointerEvents: 'none',
  [theme.breakpoints.up('xl')]: {
    top: 0,
    left: 0,
    width: '100%', // Change width to 100%
    height: '100vh',
    position: 'fixed',
  },
}));

export default function HomeHero({ shareMode = false }) {
  const router = useRouter();
  const isDesktop = useResponsive('up', 'md');
  const { scrollYProgress } = useScroll();
  const [hide, setHide] = useState(false);
  const { translate } = useLocales();
  const [safeAreaInsetTop] = useAtom(safeAreaInsetTopAtom);
  const boxRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [innerWidth, setInnerWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const [isMounted, setIsMounted] = useState(false);

  // Get UTM source from URL
  const utmSource = typeof router.query.utm_source === 'string' ? router.query.utm_source.toLowerCase() : undefined;

  // Get categories from URL, with UTM source logic
  const categories = (() => {
    // If coming from TikTok, set specific categories
    if (utmSource === 'tiktok') {
      return ['categories.fashion', 'categories.city', 'categories.carsAndBikes'];
    }
    // Otherwise use existing category logic
    return typeof router.query.categories === 'string' ? router.query.categories.split(',').filter(Boolean) : undefined;
  })();

  // Dynamic headline based on category and UTM source
  const getHeadline = () => {
    // TikTok-specific headline
    if (utmSource === 'tiktok') {
      return translate('home.homeHeroTitleTikTok');
    }

    // Existing headline logic
    if (!categories?.length) {
      return translate('home.homeHeroTitlePhotographer');
    }

    // You can add specific headlines for different categories
    if (categories.includes('categories.business')) {
      return translate('home.homeHeroTitleBusiness');
    }

    if (categories.includes('categories.headshots')) {
      return translate('home.homeHeroTitleHeadshots');
    }

    if (categories.includes('categories.art_styles')) {
      return translate('home.homeHeroTitleArt');
    }

    if (categories.includes('categories.aiDating')) {
      return translate('home.homeHeroTitleDating');
    }

    return translate('home.homeHeroTitlePhotographer');
  };

  useEffect(() => {
    // Wait for component to mount and ref to be available
    if (boxRef.current) {
      gsap.fromTo(boxRef.current, { opacity: 0, y: -50 }, { opacity: 1, y: 0, duration: 1, ease: 'power3.out' });
    }
  }, [isMounted]); // Add isMounted as dependency

  useEffect(() => {
    const handleScroll = (scrollHeight: number) => {
      setHide(scrollHeight > 0.8);
    };
    const unsubscribe = scrollYProgress.on('change', handleScroll);
    return () => unsubscribe();
  }, [scrollYProgress]);

  useEffect(() => {
    const handleResize = () => {
      setScale(+(window.innerHeight / boxRef.current?.clientHeight!).toFixed(2) - 0.25);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle window measurements after mount
  useEffect(() => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
    setIsMounted(true);

    const handleResize = () => {
      setInnerWidth(window.innerWidth);
      setInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Only render content after mount
  if (!isMounted) {
    return null; // or a loading placeholder
  }

  return (
    <>
      {/* @ts-ignore */}
      <StyledRoot sx={{ ...(hide && { opacity: 1 }), transition: 'opacity 0.3s ease-in-out' }} style={cssVariables}>
        <Box className={styles['gradient-bg']} sx={{ height: '100vh' }}>
          <div className={styles['gradients-container']}>
            {isIOS ? (
              <>
                <div className={styles['g1']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g2']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g3']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g4']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g5']} style={{ top: '50%', width: '100%' }}></div>
                <div className={styles['g6']} style={{ top: '50%', width: '100%' }}></div>
              </>
            ) : (
              <>
                <div className={styles['g1']} style={{ top: '0%' }}></div>
                <div className={styles['g2']} style={{ top: '0%' }}></div>
                <div className={styles['g3']} style={{ top: '0%' }}></div>
                <div className={styles['g4']} style={{ top: '0%' }}></div>
                <div className={styles['g5']} style={{ top: '0%' }}></div>
                <div className={styles['g6']} style={{ top: '0%' }}></div>
              </>
            )}
          </div>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            pt: `${isIOS && safeAreaInsetTop}px`,
            flexGrow: 1, // Allow the content to grow and fill available space
            display: 'flex', // Use flexbox for vertical alignment
            flexDirection: 'column', // Stack content vertically
            justifyContent: 'center', // Center content vertically
            // transform: isWidthGreater && percentDifference > 50 ? `scale(${scale})` : 'scale(1)',
          }}
          ref={boxRef}
        >
          <Container
            maxWidth="lg"
            sx={{
              // transform: isWidthGreater && percentDifference > 50 ? `scale(${scale})` : 'scale(1)',
              transformOrigin: 'center center', // Ensure scaling is centered
            }}
          >
            <Stack spacing={isDesktop ? 6 : innerHeight < 800 ? 1 : 3} sx={{ ...(innerHeight < 700 && { pt: 10 }) }}>
              <Headline text={getHeadline()} sx={{ textAlign: 'center' }} />
              <MagicWand categories={categories} />
              <Box
                sx={{
                  flexDirection: 'column',
                  gap: 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  backdropFilter: 'blur(8px)',
                  padding: '1.5rem 2rem',
                  borderRadius: '16px',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  width: 'auto',
                  margin: '0 auto',
                  alignSelf: 'center',
                  display: 'inline-flex',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    letterSpacing: 0.5,
                    fontSize: 'clamp(0.9rem, 2vw, 1.5rem)',
                    textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  {translate('home.homeHeroDescription')}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    letterSpacing: 0.5,
                    fontSize: 'clamp(0.8rem, 1.8vw, 1.4rem)', // Slightly smaller font size
                    textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  {translate('home.homeHeroDescription2')}
                </Typography>
              </Box>
              {/* {innerHeight > 800 && (
                <Typography variant="h2" sx={{ letterSpacing: 0.2, opacity: 0.7, textAlign: 'center' }}>
                  <HowItWorks />
                </Typography>
              )} */}

              {/* {innerHeight > 800 && <BigHomeButton text={translate('AIPortraitShowcase.seeTheMagic')} url="#AIShowcase" />} */}
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <LuxuryButton
                  text={translate('buttons.signInToCreateYourPictures')}
                  url={PATH_AUTH.login}
                  color="warning"
                  sx={{ display: 'inline-flex', width: 'fit-content', mb: 1 }}
                />
              </Box>

              {innerHeight >= 800 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    zIndex: 3,
                    position: 'absolute',
                    bottom: -80,
                    left: '50%',
                    right: 0,
                    transform: 'translateX(-50%)',
                    flexDirection: 'column',
                    cursor: 'pointer',
                  }}
                >
                  <Iconify
                    icon={'mdi:arrow-down'}
                    sx={{
                      width: 48,
                      height: 48,
                      opacity: 0.7,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      marginTop: 1,
                      animation: 'scrollText 2s infinite',
                      color: 'white',
                    }}
                  >
                    {translate('buttons.moreInfo')}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Container>
        </Box>
        {/* <Stamp text={translate('buttons.free200')} /> */}
      </StyledRoot>
      <Box sx={{ minHeight: { md: '100vh', zIndex: 1000 } }} />
    </>
  );
}
