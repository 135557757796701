import React from 'react';
import { Box, Typography, Modal, useTheme } from '@mui/material';
import { atom, useAtom } from 'jotai';
import { keyframes } from '@emotion/react';

// Atom to manage the state of the info message modal
export const infoMessageAtom = atom({
  open: false,
  message: '',
  content: null as React.ReactNode | null, // Optional content
});

const pulse = keyframes`
  0%, 100% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
`;

const FullscreenInfoMessage: React.FC = () => {
  const [infoMessage, setInfoMessage] = useAtom(infoMessageAtom);

  // Function to handle closing the modal
  const handleClose = () => {
    setInfoMessage({ ...infoMessage, open: false });
  };

  const theme = useTheme();

  return (
    <Modal open={infoMessage.open} onClose={handleClose} sx={{ zIndex: 9999 }}>
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 1)"
        zIndex={1300}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }}
      >
        <Box sx={{ position: 'relative', marginTop: theme.spacing(-20) }}>
          <Typography variant="h3" gutterBottom>
            {infoMessage.message}
          </Typography>
          {infoMessage.content && <Box sx={{ mt: 2 }}>{infoMessage.content}</Box>}
          {!infoMessage.content && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              {[0, 1, 2].map((index) => (
                <Box
                  key={index}
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    mx: 0.5,
                    animation: `${pulse} 1.4s ease-in-out ${index * 0.2}s infinite`,
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default FullscreenInfoMessage;
