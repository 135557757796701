const en = {
  AIGeneration: {
    title: 'AI Image Generator',
    description: 'Generate stunning AI images with our advanced image generation technology.',
    startNow: 'Start Now',
  },
  referralModal: {
    headline: 'Want free coins?',
    description: `Share the love, double the rewards! 🎁 When your friends join, you'll get free coins and trainings - completely free!`,
    userRewardDescription:
      'If 5 of your friends join using your link, you will get 50 coins and 1 training for free! 🎁 This is a thank you for your support and will apply for every 5 friends you refer.',
    getLink: `Get Link`,
    title: 'Your VIP Referral Link',
    rewardDescription:
      "Amazing deal for you and your friends! 🎁 When they purchase any package using your link, we'll automatically add the same package to your account - completely free! Share now and multiply your benefits!",
    copy: 'Copy Link',
    copied: 'Link copied! Ready to share! 🚀',
    statsTitle: 'Your Referral Statistics',
    referralCountPlural: 'You have referred {{count}} friends.',
    referralCountSingular: 'You have referred {{count}} friend.',
  },
  alert: {
    highDemand: `It's amazing to see such a vibrant AI portrait community! We're processing thousands of training requests, and servers are feeling the love. To ensure fairness, we're prioritizing paying members. The free trial is on hold, but you can still join by purchasing some coins. This gets you faster access and supports our growth. Thanks for being a part of this!`,
  },
  AspectRatio: {
    videoFormat: `Video Format`,
    selectFormat: `Select format`,
    selectIdealFormat: `Select the ideal format for your target platform`,
    idealFor: `Ideal for {{platforms}} content`,
    formats: {
      portrait: {
        label: 'Portrait',
        description: 'Perfect for Instagram Stories and Pinterest',
      },
      square: {
        label: 'Square',
        description: 'Ideal for Instagram Feed and Facebook Posts',
      },
      mobile: {
        label: 'Mobile',
        description: 'Optimized for TikTok and YouTube Shorts',
      },
      landscape: {
        label: 'Landscape',
        description: 'Best for YouTube and Traditional Video',
      },
    },
    platforms: {
      instagramStories: 'Instagram Stories',
      pinterest: 'Pinterest',
      facebookStories: 'Facebook Stories',
      instagramFeed: 'Instagram Feed',
      facebook: 'Facebook',
      linkedin: 'LinkedIn',
      tiktok: 'TikTok',
      youtubeShorts: 'YouTube Shorts',
      instagramReels: 'Instagram Reels',
      youtube: 'YouTube',
      vimeo: 'Vimeo',
      twitter: 'Twitter',
    },
  },
  StoryCreator: {
    finish: `Finish`,
    acceptStyle: `Accept Style`,
    back: `Back`,
    skipCharacter: `Skip Character`,
    acceptCharacter: `Accept Character`,
    generating: `Generating...`,
    generateStory: `Generate Story`,
    acceptStory: `Accept Story`,
    next: `Next`,
    stylePreview: `Style Preview`,
    regeneratePreview: `Regenerate Preview`,
    previewImage: `Preview {{number}}`,
    howItWorks: `How it works`,
    regenerate: `Regenerate`,
    regeneratingImage: `Regenerating image...`,
    waitForPreviousScene: `Waiting for previous scene`,
    lockScene: `Lock Scene`,
    unlockScene: `Unlock Scene`,
    scene: `Scene {{number}}`,
    prompt: `Prompt`,
    new: `New`,
    preview: `Preview`,
    promptNavigator: `{{current}} / {{total}}`,
    videoCreation: {
      generateFirstVideo: `Generate your first video`,
      createNewVariant: `Create new variant`,
      generatingVideo: `Generating video...`,
      scene: `Scene {{number}}`,
      howItWorksSteps: {
        step1: `For each scene, click the + button to generate a video`,
        step2: `Wait for the video generation to complete - you'll see a progress indicator while processing`,
        step3: `When complete, you can play the video or generate additional variants`,
        step4: `If a video fails, try generating a new variant`,
      },
    },
    styleCreation: {
      title: `Choose Your Story's Visual Style`,
      subtitle: `Define the artistic style that will bring your story to life`,
      selectVisualStyle: `Select a Visual Style`,
      stylePrompt: `Style Prompt`,
      howItWorksSteps: {
        step1: `Review the AI-generated style prompt or write your own custom prompt to define the visual style`,
        step2: `Click "Preview Style" to generate sample images based on the style prompt`,
        step3: `Use the navigation buttons to generate new style descriptions or preview different styles`,
        step4: `Once you're happy with the style, click "Next" to move to the next step`,
      },
    },
    list: {
      title: `My Stories`,
      createNew: `Create New Story`,
      heroTitle: `Unleash Your Creative Stories`,
      heroDescription: `Transform your ideas into stunning visual narratives with AI-powered story generation. Create characters, design scenes, and bring your imagination to life with custom artwork and videos - all in one place.`,
      noImage: `No image generated yet`,
      deleteModal: {
        title: `Delete Story`,
        message: `Are you sure you want to delete "{{title}}"? This action cannot be undone.`,
        cancel: `Cancel`,
        confirm: `Delete`,
        deleting: `Deleting...`,
      },
      status: {
        draft: `Draft`,
        inProgress: `In Progress`,
        completed: `Completed`,
        archived: `Archived`,
      },
    },
    ideaStep: {
      title: `AI Story Generator`,
      subtitle: `Write a theme or detailed story idea - our AI will create a unique story with matching images.`,
      howItWorksSteps: {
        step1: `Enter your idea. It can be something like "Photo Shoot in Berlin" or "A story about a girl who finds a magical hat that makes her invisible."`,
        step2: `Choose the number of scenes (1-10).`,
        step3: `Select the image format you want to use.`,
      },
      storyIdea: {
        title: `Your Story Idea`,
        subtitle: `Share your idea - from a simple theme to a detailed story concept. The more details you provide, the more closely the AI will follow your vision.`,
        placeholder: `Simple theme like 'magical forest' or detailed plot - AI adapts to your input`,
        characterCount: `{{count}}/2000 characters - Be as descriptive as possible`,
      },
      scenes: {
        title: `Number of Scenes`,
        subtitle: `Define key moments in your story`,
        count: `{{count}} scene{{plural}}`,
      },
      buttons: {
        generating: `Crafting Your Story...`,
        generate: `Generate Story`,
        continue: `Continue to Next Step`,
      },
      existingStory: {
        title: `Story In Progress`,
        message: `Once created, you can not change the base idea or number of scenes. Delete the story to start over or continue to the next step.`,
      },
    },
    editingStep: {
      title: `Craft your story scene by scene.`,
      regenerating: `Regenerating missing scenes...`,
      howItWorksSteps: {
        step1: `Click the edit icon to edit any scene's headline or content`,
        step2: `Make your changes and click save to confirm them`,
        step3: `Use "Request AI Changes" to get AI suggestions for improving your story`,
        step4: `When you're happy with your story, click "Accept Story" to proceed`,
      },
      scene: {
        headlinePlaceholder: `Enter scene headline...`,
        contentPlaceholder: `Enter scene content...`,
        editTooltip: `Edit Scene`,
        saveTooltip: `Save Changes`,
      },
      requestChanges: {
        title: `Request AI Changes`,
        placeholder: `Describe the changes you want...`,
        button: `Request Changes`,
        processing: `Processing...`,
      },
      actions: {
        back: `Back`,
        acceptStory: `Accept Story`,
      },
    },
    steps: {
      storyIdea: `Story Idea`,
      storyEditing: `Story Editing`,
      visualStyle: `Visual Style`,
      characterCreation: `Character Creation`,
      sceneCreation: `Scene Creation`,
      videoCreation: `Video Creation`,
    },
    navigation: {
      backToStories: `Back to Stories`,
      error: `Failed to update step`,
    },
    sceneCreation: {
      title: `Create Your Scenes`,
      subtitle: `Generate and select images for each scene of your story`,
      howItWorksSteps: {
        step1: `For each scene, you'll see two generated images to choose from`,
        step2: `Use the regenerate button to generate new images or the lock button to select your preferred image`,
        step3: `You must lock one image before proceeding to the next scene`,
        step4: `Click the zoom icon or the image to view in full screen`,
      },
    },
    characterCreation: {
      title: `Design Your Character's Appearance`,
      subtitle: `Define how your character looks and what they wear`,
      howItWorksSteps: {
        step1: `Review the AI-generated character description or write your own custom description`,
        step2: `Click "Preview AI Character" to generate sample images of your character`,
        step3: `Use the navigation buttons to generate new descriptions or preview different looks`,
        step4: `Once you're happy with your character, click "Accept Character" to move to the next step`,
      },
      prompts: {
        title: `Define Your Character`,
        labelText: `Character Description`,
      },
    },
  },
  AIShortVideoShowcase: {
    title: `Bring Your Portraits to Life`,
    experienceTheFuture: `Your Portraits, Now with a Heartbeat`,
    transformYourPortraits: `Create short videos from any Portrait you have generated.`,
    explanation: `Generate your perfect portrait first, then watch as our AI transforms it into a captivating short video. One click and your portrait will come to life.`,
    readyToStart: `Ready to Create Your Own AI Videos?`,
    createYourOwn: `Add a touch of motion magic to your portraits.`,
    startCreating: `Start Creating Now`,
  },
  chatInterface: {
    newConversation: 'New Conversation',
    createNewConversation: 'Create New Conversation',
    create: 'Create',
    conversationTitle: 'Conversation Title',
    typeYourMessage: 'Type your message...',
    generateThisPicture: `Generate this Picture`,
    startToChat: `Start to Chat`,
    startToExplore: `Start to Explore`,
    startToChatDescription: `Chat with our AI to create your perfect Photo Shoot or Freestyle Image Generation.`,
    startToExploreDescription: `Explore endless ready-made Portrait Styles.`,
    startToChatTitle: `MyPortrait Studio Muse`,
    startToExploreTitle: `Discover Portrait Styles`,
    newCharacterChatMessage: `Hi. Please help me to make some portraits for me.`,
    newPhotoEditChatMessage: `Hi. Please help me to edit this picture.`,
    newArtistChatMessage: `Hi. Please help me to create an artwork for me.`,
    startANewChat: `Start a New Chat`,
    characterMode: `Character Mode`,
    imageGenerationMode: `Image Generation Mode`,
    characterModeDescription: `In Character Mode, you'll generate images based on your currently selected character.`,
    imageGenerationModeDescription: `In Image Generation Mode, you can create any type of image without restrictions. Let your imagination run wild!`,
    chooseYourCreationMode: `Choose Your Creation Mode`,
    chooseYourCreationModeDescription: `Select how you want to generate images in this conversation.`,
    imageGeneration: `Image Generation`,
    noCharacterSelected: `No character selected`,
    freeImageGenerationMode: `Free Image Generation Mode`,
    startNewArtisticChat: `Begin Creative Image Generation`,
    startNewArtisticChatDescription: `Alternatively, you can initiate a new freestyle image generation chat. In this mode, you have the freedom to describe any concept, and our AI will create a custom image based on your description.`,
    newArtistChatTitle: `Begin Artistic Image Generation`,
  },
  AIImageComparison: {
    title: `Photo Editing with AI`,
    description: `You upload a picture and describe what you want to change. Our AI will then edit the picture for you.`,
    original: `Original`,
    generated: `AI Generated`,
  },
  status: {
    deleted: `Deleted`,
    createdAt: `Created At`,
    preview: `These portraits are AI-generated examples how your portraits could look like.`,
    failed: `Sorry. I could not generate your portraits. Your coins have been refunded. Please try again.`,
    thinking: `Thinking...`,
    youHaveSeenAllThePictures: `You have seen all the pictures`,
  },
  chatMessage: {
    generatingYourPortraits: `Creating your new Portraits...`,
    welcome: `Welcome to MyPortrait.studio`,
  },
  maintenanceMessage: {
    title: `We're Sorry`,
    message: `Our website is currently undergoing maintenance. Please check back later.`,
  },
  HeroSection: {
    title: `Create stunning AI portraits of yourself`,
    description: `Perfect for business, Instagram, or Tinder. Upload your pictures and let our AI create whatever you want.`,
  },

  UseCaseSection: {
    title: 'Level Up your Presence on Social Media',
    description: 'Explore how our AI-generated images can elevate your presence across various platforms.',
    useCases: {
      business: {
        title: 'Business',
        description: 'Enhance your business presence with AI-generated visuals that captivate your audience.',
      },
      instagram: {
        title: 'Instagram',
        description: 'Create stunning Instagram posts with tailor-made AI-generated images for higher engagement.',
      },
      tinder: {
        title: 'Tinder',
        description: 'Boost your Tinder profile with unique AI-generated pictures that make you stand out.',
      },
      professionalNetworking: {
        title: 'Professional Networking',
        description: 'Enhance your professional presence with AI-generated images that convey confidence and competence.',
      },
      artistPortfolio: {
        title: 'Artist Portfolio',
        description: 'Showcase your artistic creations with AI-enhanced visuals that highlight your unique style and creativity.',
      },
      eCommerceProductModels: {
        title: 'E-commerce Product Models',
        description: 'Elevate your product listings with AI-generated models that attract and engage potential buyers.',
      },
      // Add more use cases as needed following the same structure
    },
  },
  welcomeTour: {
    tabsIntro: `Here you can choose from different categories of portraits. Under My Style, you can generate your custom style. Photo Shoot offers photorealistic portraits, while Fantasy Art provides portraits with a more artistic style.`,
    categoryIntro: `These are various styles you can choose from to generate your portraits. Click on a style to see all your portraits in that style. Scroll down to explore the many different styles available.`,
    myProfileIntro: `You can train our AI on different Characters. Each Character is a collection of pictures of one person. Switch between characters here.`,
    startWithTrainingIntro: `Start here with your first Character. Upload only your best pictures to get the best results.`,
    pictureContainerIntro: `Your generated portraits. If you have none generated yet, you will see example portraits.`,
    generateIntro: `Describe what you want to create, then click the send button to generate your portraits.`,
    generatePhotoEditIntro: `Choose the Photo Edit Mode to edit your portrait. Our AI will create a new portrait based on your description and the reference image.`,
    generateFreestyleIntro: `Select the Freestyle Mode to create entirely new portraits. MyPortrait will generate fresh images based on your description and chosen style.`,
    scrollDownIntro: `Scroll Down`,
  },
  foundationPictures: {
    noPictures: `To begin editing, please upload a picture.`,
    uploadPictures: `Upload Your Pictures`,
  },
  restartServer: {
    message: 'There is a new version of the app available. Please reload the app to continue.',
    reload: 'Reload App',
  },
  AIPortraitShowcase: {
    title: 'Enjoy 20 Free AI-Generated Portraits Today :)',
    subtitle:
      'Join now and kickstart your journey with stunning, personalized portraits crafted by our advanced AI. Experience the magic of transforming your photos into artistic masterpieces for free!',
    summaryTitle: 'Transform Your Images with AI',
    summaryDescription:
      'Experience the next level of portrait customization with our state-of-the-art AI technology. Upload your photos and see the magic unfold.',
    exclusiveOffer: 'Exclusive Offer: Get 20 Free Portraits as a Welcome Gift!',
    getStartedNow: 'Begin Your Adventure!',
    male: 'Male',
    female: 'Female',
    uploadYourBestPhotos: 'Upload Your Best Photos',
    uploadBeautifulPicturesOfYourself: 'Select your best photos to train our AI effectively.',
    resultTitle: 'Generate endless AI Portraits',
    resultDescription:
      'Achieve remarkable transformations using only your words. Describe what you wish to create, and let our AI handle the rest.',
    thisImageIsAIGenerated: 'This image was expertly generated by AI.',
  },
  styles: {
    haircut: `Hairstyle`,
    haircutDesktop: `Choose your Hairstyle`,
    customStyle: `Custom AI instructions`,
    age: `Age`,
    hairColor: `Hair Color`,
    eyeColor: `Eye Color`,
    hairstyle: `Hair Style`,
    yearsOld: `{{age}} years old`,
    backgroundEnabled: `Include Background`,
    backgroundDisabled: `Exclude Background`,
  },
  colors: {
    Blonde: 'Blonde',
    Brown: 'Brown',
    Black: 'Black',
    Red: 'Red',
    Auburn: 'Auburn',
    Chestnut: 'Chestnut',
    Platinum: 'Platinum',
    Gray: 'Gray',
    White: 'White',
    notSet: 'Original',
    Blue: 'Blue',
    Green: 'Green',
    Hazel: 'Hazel',
    Amber: 'Amber',
    Violet: 'Violet',
    customColor: 'Custom Color',
  },
  payment: {
    paymentSuccess: `Thank you! Your Coins will be added to your account in a few moments.`,
    helpModal: {
      title: `How to Pay Using Crypto`,
      description: `Follow these steps to complete your payment using cryptocurrency. We accept payments on the Polygon and Base blockchains.`,
      step1: {
        title: `1. Supported Blockchains`,
        description: `We currently accept payments on the Polygon and Base blockchains.`,
      },
      step2: {
        title: `2. Connecting Your Wallet`,
        description: `If your preferred wallet (like MetaMask or Trust Wallet) doesn't appear, tap the "Copy Link" button. Open your wallet app and paste the link into its built-in browser. Wallet browsers function similarly to regular browsers such as Google Chrome or Firefox.`,
      },
      step3: {
        title: `3. Accessing the Payment Link`,
        description: `After pasting the link into your wallet's browser, you'll be directed to the payment screen. In this guide, we're using MetaMask as an example.`,
      },
      step4: {
        title: `4. Connecting the Payment Link to Your Wallet`,
        description: `On the payment screen, tap the MetaMask button (or the button corresponding to your wallet). This action will connect the payment link to your wallet.`,
      },
      step5: {
        title: `5. Payment Amount Details`,
        description: `Your wallet will display your balance. For instance, you might have $11.40 worth of POL tokens. In this transaction, you'll only pay $4.99 worth of POL on the Polygon blockchain.`,
      },
      step6: {
        title: `6. Changing Payment Token or Blockchain`,
        description: `To select a different token or blockchain, tap the right arrow on the screen. A list of available options will be presented for your selection.`,
      },
      step7: {
        title: `7. Completing the Payment`,
        description: `Tap the large blue "Pay" button. Your wallet will then prompt you to confirm the transaction. Approve the transaction within your wallet.`,
      },
      step8: {
        title: `8. Done!`,
        description: `Once the transaction is confirmed, your payment is complete. Enjoy your new portrait!`,
      },
      close: `Close`,
    },
    errors: {
      loginRequired: `Please log in to make a purchase.`,
      linkNotGenerated: `Failed to generate payment link. Please try again.`,
      creationFailed: `Failed to create payment. Please try again.`,
    },
    loading: `Loading...`,
  },
  inAppEvent: {
    title: `Generate 20 Portraits of yourself for free!`,
    description: `We're celebrating our launch with a special offer. Generate 20 portraits of yourself for free!`,
    button: `Generate`,
  },
  uploadGuide: {
    title: `Read This Guide for Optimal AI Results!`,
    subTitle: `Uploading the correct pictures is crucial for the AI to accurately learn your facial features.`,
    title1: `Only one Person in the Picture`,
    content1: `We can only train the AI with one person per training. If you have multiple people in the picture, we will train the AI most likely with the wrong person.`,
    title2: `Perfect Face Angles`,
    content2: `Upload clear photos from the front, side, and at 45-degree angles to capture all aspects of your face.`,
    title3: `Show Different Expressions`,
    content3: `Include neutral, smiling, and serious expressions to provide a comprehensive range of data for the AI. Do not use exaggerated expressions.`,
    title4: `Good Lighting Matters`,
    content4: `Ensure your photos are well-lit while avoiding harsh shadows and overexposure.`,
    title5: `High-Quality Photos`,
    content5: `Use clear and sharp images without blur to ensure the best results for AI training.`,
    title6: `Avoid Obstructions`,
    content6: `Remove sunglasses, hats, or any items that block your face. Glasses without tint are acceptable.`,
    title7: `Different Backgrounds`,
    content7: `Provide photos with  different backgrounds to enhance AI understanding.`,
    title8: `Consistent Camera Perspective`,
    content8: `Ensure your photos are taken with a straight-on view. Avoid angles that distort facial features, such as views from above or below. These images are solely for AI analysis, so maintaining a natural perspective is crucial for accurate facial recognition.`,
    title9: `Use Your Phone for Best Results`,
    content9: `It is helpful if you make the picture via your phone now if you do not have such pictures as described. This ensures you will get the best possible portraits.`,
  },
  imageSelectionGuide: {
    title: `Image Selection Guide`,
    message: `When uploading your pictures, please follow these guidelines:`,
    guidelines: [
      {
        title: 'Quality',
        description: 'Use high-resolution images with good lighting.',
      },
      {
        title: 'Variety',
        description: 'Include 70% close-up shots of your face from different angles and 30% full-body shots.',
      },
      {
        title: 'Backgrounds',
        description: 'Ensure diverse backgrounds to help the AI learn better.',
      },
      {
        title: 'Expressions',
        description: 'Capture a range of natural expressions. Avoid exaggerated expressions.',
      },
      {
        title: 'Avoid',
        description: 'Blurry, dark, or group photos, and images with obstructions like hands on the face.',
      },
    ],
    reminder:
      "Remember, the better the images, the better the AI can generate your portraits. We can't generate portraits from bad images and we cannot refund training coins if you upload images that don't meet the requirements.",
  },
  portraitDropDown: {
    chooseYourCharacter: `Select Your Character`,
    uploadNewProfile: `Create a new Character`,
  },
  howItWorks: {
    title: `Ready for Your AI Photoshoot? Let's Start!`,
    subTitle: `Give your AI photographer {{minUserPictures}}-{{maxUserPictures}} photos to work with, and watch the magic happen! Think of it as teaching AI your best features - the more angles and expressions you share, the more stunning your generated portraits will be.`,
    tips: [
      {
        title: `Crystal Clear Photos`,
        description: `The better your initial shots, the more amazing your AI portraits. Think bright lighting and sharp focus!`,
      },
      {
        title: `Mix Up Your Shots`,
        description: `Close-ups, mid-shots, full body - variety is your secret weapon for incredible AI portraits.`,
      },
      {
        title: `Show All Your Angles`,
        description: `Front view, profile, 3/4 angle - help AI understand what makes you uniquely you!`,
      },
      {
        title: `Change Your Scene`,
        description: `Different backgrounds in your photos help AI create more dynamic and versatile portraits.`,
      },
    ],
    examples: {
      title: `See What Others Are Uploading`,
    },
    aiPowered: {
      title: `Where AI Meets Amazing: Your Portrait Revolution`,
      description: `Ready to turn heads on every platform? Our AI will generate scroll-stopping portraits for your Instagram, magnetic photos for Tinder, and professional shots for LinkedIn - all from your uploaded photos. Time to show the world your best self!`,
      description2: `Check out these AI-generated masterpieces below - this is what happens when your photos meet our cutting-edge AI technology. Excited? You're about to look even better!`,
    },
  },
  uploadManager: {
    uploading: `Uploading...`,
    processing: `Processing...`,
    totalFiles: `Total Files: {{count}}`,
    totalSize: `Total Size: {{size}} MB`,
    addPictures: `Add Pictures`,
    whichPictureToUploadDescription: `To get the best results, upload high-quality images. We recommend a mix of 70% close-up shots of your face from various angles and 30% full-body shots. The better the image quality, the more accurately the AI can learn and generate detailed portraits. Variety in your images helps the AI to capture all unique aspects of your appearance. Try to use different backgrounds in most of your pictures.`,
    pleaseWait: `Please wait till the images are uploaded and processed. Do not leave the app until the process is finished.`,
  },
  profilePictureSet: {
    modelTrainingCompleted: `Model Training Completed`,
    modelTrainingFailed: `Model Training Failed. Please upload Pictures that are in good lighting and have a different background. You can start a new training. We have refunded your coins.`,
    progressReadyToGenerate: `Finalizing the current workflow...`,
    yourAIProfiles: `Your AI Characters`,
    yourAIProfilesDescription: `A character is a collection of your photos that our AI uses to learn and generate personalized portraits. Once trained, you can choose your character to create unique AI-generated images that reflect your style and likeness.`,
    addNewProfilePictureSet: `Add a new Character`,
    statusStatus: {
      error: `Error`,
      empty: `No Pictures`,
      saved: `Saved`,
      ready: `Ready`,
      startTraining: `Training in Progress`,
    },
    pictures: `Pictures`,
    status: `Status`,
    insufficientBalance: `Insufficient Coins / Training Balance`,
    insufficientBalanceDescription: `You need at least one Training Coin to add a new Character.`,
    insufficientBalanceButton: `Add Coins`,
    trainingComplete: `Training Finished`,
    trainingCompleteDescription: `The training process has completed successfully.`,
    generatePortraitsButton: `Generate Portraits with this Character`,
    chooseThisCharacterButton: `Choose this Character`,
    trainingError: `Training Error`,
    trainingErrorDescription: `An error occurred during the training process. Please delete this character and try again or contact support if the issue persists.`,
    profileName: `Character Name`,
    uploadedPictures: `Uploaded Pictures`,
    deleteProfile: `Delete this Character`,
    deleteProfileDescription: `You are about to delete this character. This action will permanently remove all generated portraits associated with this character. Are you sure you want to proceed?`,
  },
  genderSelection: {
    title: `We're almost ready to start the training :)`,
    description: `Please indicate whether the uploaded pictures are of a man or a woman. This information is crucial for training the AI model to produce the most accurate portraits.`,
    womanButton: `Woman`,
    manButton: `Man`,
  },
  app: {
    warning: `Warning`,
    confirm: `Confirm`,
    cancel: `Cancel`,
    loading: `Loading`,
    youHaveSeenAllYourPictures: `You have seen all your pictures.`,
    noInternetConnection: `No Internet Connection`,
    noInternetConnectionDescription: `Please check your internet connection and start the app again.`,
    account: {
      general: `General`,
      billing: `Billing`,
      notifications: `Notifications`,
      changePassword: `Change Password`,
      currentPassword: `Current Password`,
      newPassword: `New Password`,
      confirmNewPassword: `Confirm New Password`,
      enablePushNotifications: `Enable Push Notifications`,
      enableEmailNotifications: `Enable Email Notifications`,
    },
    homePageMenu: {
      home: `Home`,
      howItWorks: `How it works`,
      signIn: `Sign In`,
      pricing: `Pricing`,
      faq: `FAQ`,
    },
    breadcrumbs: {
      dashboard: 'Home',
      profile: 'Character',
      upload: 'Upload',
      purchaseOptions: 'Coin Packs',
      settings: 'Settings',
      selectMode: 'Discover',
      chat: 'Chat',
      videos: 'My Videos',
      myStories: 'My Stories',
    },
    contact: {
      youHaveQuestions: `You have questions?`,
      feelFreeToContactUs: `Feel free to contact us.`,
      wellBeGladToHearFromYou: `We'll be happy to hear from you, buddy.`,
      weGotYourMessage: `We got your message!`,
      name: `Name`,
      email: `Email`,
      subject: `Subject`,
      message: `Enter your Message here`,
      send: `Submit Your Message`,
      sending: `Sending...`,
      error: `There was an error sending your message. Please try again.`,
      validation: {
        nameRequired: `Name is required`,
        emailRequired: `Email is required`,
        subjectRequired: `Subject is required`,
        messageRequired: `Message is required`,
      },
    },
    faq: {
      faq: `FAQ`,
      title: `Frequently Asked Questions`,
      contactUsIfYouHaveQuestions: `Contact us if your question is not answered`,
      ifYouCanNotFind: `If you can't find the answer to your question in our FAQ, please don't hesitate to contact us. We're here to help!`,
      contactUs: `Contact us`,
    },
    startWithTraining: {
      title: 'Your Perfect Portrait Session Awaits!',
      subTitle: 'Join thousands who are already creating their most stunning portraits ever. Your perfect photos are waiting!',
      mention: `Example portraits created with our technology. You can create your own portraits with your own pictures after the upload.`,
      genderMan: `Example Man`,
      genderWoman: `Example Woman`,
      genderRandom: `Random`,
    },
    pictures: `Pictures`,
    inWork: `Processing`,
    new: `New`,
    imageNSFW: `Image blurred due to NSFW content and won't be displayed.`,
    examplePicture: `Example Picture`,
  },
  time: {
    minuteAbbreviation: `m`,
    secondAbbreviation: `s`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
    doYouNeedOurHelp: `Do you need our help?`,
  },
  generateVideo: {
    noVideosGeneratedYet: `No Videos Generated Yet`,
    noVideosGeneratedYetDescription: `Ready to bring your images to life? Visit any of your generated pictures and click the video generation button. Your created videos will appear here automatically.`,
    createVideo: `Create a Short Video`,
    experimentalFeature: `Transform your image into a brief 5-second video. Please note, this is an experimental feature, and video quality may vary.`,
    pending: `Pending`,
    pendingDescription: `We found a free slot for your video.`,
    processing: `Processing`,
    processingDescription: `Your video will be ready soon.`,
    initiated: `Initiating`,
    initiatedDescription: `Your video creation is about to start. It may take some time depending on demand.`,
  },
  buttons: {
    create: `Create`,
    signInToCreateYourPictures: `CREATE YOUR PORTRAIT`,
    outOfCoinsAddMoreCoinsDesktop: `You're out of coins. Add more coins to continue.`,
    outOfCoinsAddMoreCoinsMobile: `Get more coins.`,
    startToCreateYourPictures: `Start to create your pictures`,
    shareAndSave: `Share & Save`,
    generateVideo: `Create a Video from this Image`,
    generateVideoCoins: `24 Coins`,
    rotate: `Rotate`,
    EnableEmailNotifications: `Enable email notifications`,
    EnablePushNotifications: `Enable push notifications`,
    StarTheUploadOfYourPictures: `Start uploading your Pictures`,
    pleaseWait: `Please wait...`,
    remove: `Remove`,
    delete: `Delete`,
    ok: `OK`,
    cancel: `Cancel`,
    save: `Save`,
    close: `Close`,
    contactUs: `Contact Us`,
    continue: `Continue`,
    showAll: `Show All`,
    download: `Download`,
    share: `Share`,
    shareHeadlineInMessage: `Check out this awesome portrait I created with MyPortrait Studio!`,
    saveChanges: `Save Changes`,
    confirm: `Confirm`,
    watchTrailer: `Watch our Trailer`,
    free200: `20 Portraits for Free!`,
    moreInfo: `More Info`,
    change: `Change`,
    upload: `Upload`,
  },
  generateMenu: {
    pleaseHoldOn: `Please hold on, we're processing your image...`,
    ourPhotoEditorShinesWithPortraits: `Our Photo Editor shines with portraits. It might struggle with unique angles or distant subjects.`,
    switchToFreestyleMode: `Switch to Freestyle Mode`,
    switchToPhotoEditMode: `Switch to Photo Editor Mode`,
    pleaseSelectOrUpload: `Please select or upload a Picture to start editing.`,
    generatePrompt: `You can add instructions to change the style of the generated portrait.`,
    modeDescriptionPhotoEdit: `The Photo Editor Mode allows you to edit your Reference Image. It is meant to be used for realistic edits. If you want to create render portraits, you should use the Freestyle Mode.`,
    modeDescriptionFreestyle: `The Freestyle Mode generate Portraits completely new. It is the most creative mode which has no limitations but also no guidance. `,
    photoEditorModeTitle: `Photo Editor Mode`,
    freestyleModeTitle: `Freestyle Mode`,
    photoEditorModeDescription: `The Photo Editor Mode allows you to edit your Reference Image. It is meant to be used for realistic edits. If you want to create render portraits, you should use the Freestyle Mode.`,
    detailEnhancerPhotoEdit: `Guidance`,
    detailEnhancerDescriptionPhotoEdit: `Adjust how closely the AI should follow the reference image.`,
    photoEditor: `Photo Editor`,
    freestyle: `Freestyle`,
    artist: `Artist`,
    photoEditorMode: `Photo Editor Mode`,
    artistMode: `Artist Mode`,
    freestyleMode: `Freestyle Mode`,
    photoEditorHeadline: `Photo Editor Mode`,
    photoEditorSubHeadline: `Ready to transform your photo? Upload an image and let our AI work its magic! We'll do our best to keep it true to the original. Just give us clear instructions, and remember, small tweaks work best. Oh, and a quick heads up: we're still perfecting full-body shots and lounging poses, so stick to upper body portraits for now. Happy editing!`,
    processing: `Processing: {{progress}}%`,
    uploading: `Uploading: {{progress}}%`,
    uploadPicture: `Upload Picture`,
    tabCreateNew: `Create Content`,
    addAnotherImage: `Add another image`,
    addYourFirstImage: `Add your first image`,
    uploadNewPicture: `Upload a new picture`,
    addYourFirstImagesToEdit: `Add your first images to edit them.`,
    optionalUploadPrompt: `Upload a reference image to base your AI-generated portrait on. The AI will keep the expression, pose, and background from your reference while applying the chosen style and reinterpreting it. Your portrait will have the highest likeness to your reference.`,
    tabFoundationalImages: `Reference Images`,
    generatingNotPossibleNewModel: `You need to train a new character before generating new portraits. Our model has been updated, and you must train a new character to generate portraits of significantly higher quality.`,
    generatingNotPossibleNoFoundationPicture: `You need to select a reference picture before generating new portraits.`,
    noImagesTitle: `Your first Portrait`,
    noImagesDescription: `You can change the prompt, aspect ratio, and detail before generating your portrait. Or simply use the default settings and press the "Generate new Pictures" button to generate your portrait.`,
    generateNewPortraits: `Generate New Portraits`,
    cinematic: `Cinematic`,
    render: `3D Render`,
    none: `Off`,
    customizeAndGenerate: `Customize Your Portraits`,
    customizeAndGenerateDescription: `Customize your portrait by adjusting attributes such as hair color, expression, and lighting. Just write down your preferences and press "Generate New Portrait" after you've made your changes.`,
    pressCTRL: `Press SHIFT + Enter to generate.`,
    detailEnhancer: `Detail Enhancer`,
    detailEnhancerDescription: `This setting adjusts the details of your portrait. Skin texture, hair structure, and fabric textures as well as background details.`,
    similaritySetting: `Similarity Setting`,
    similaritySettingDescription: `Adjusts the resemblance of the generated portrait. 100% is a balance between creativity and accuracy.`,
    renderMode: `Render Mode`,
    renderModeDescription: `Select either the cinematic mode for a lifelike, film-quality effect, or the 3D rendering mode for a more stylized, artistic look that offers greater creative freedom.`,
    renderDescription: `The Render Mode focuses on a stylized, rendered appearance for generated portraits.`,
    cinematicDescription: `The Cinematic mode aims to provide the most realistic and lifelike rendering of portraits.`,
    noneDescription: `No specific render mode selected.`,
    saveStyleDescription: `Save your style to ensure it's automatically applied when you visit this category.`,
    loadStyleDescription: `Load your style`,
    restoreDefaultsDescription: `Restore Defaults`,
    deleteStyleDescription: `Delete your Style and restore Defaults`,
    generatePortraitsDescription: `Create your next set of images`,
    saveStyle: `Save your Style`,
    loadStyle: `Load your Style`,
    restoreDefaults: `Restore Defaults`,
    deleteStyle: `Delete your Style`,
    loadMobileStyleDescription: `Load your personalized settings for Prompt, Aspect Ratio, and Detail.`,
    restoreMobileDefaultsDescription: `Restore the settings to their default values.`,
    deleteMobileDescription: `Delete your saved style.`,
    maxImageGenerationQueueReached: `The maximum image generation queue has been reached. Please wait for the current generation to complete.`,
    noCoins: `Oops! You're out of coins.`,
    trainingNotFinished: `Training not finished.`,
  },
  confirmDialog: {
    deleteStyle: `Confirm Delete`,
    deleteStyleDescription: `Are you sure you want to delete your saved style? This action cannot be undone.`,
    deleteProfileTitle: `Are you sure you want to delete your account?`,
    deleteProfileDescription: `This action cannot be undone! All your generated portraits will be lost.`,
    deleteProfileButton: `Delete Account`,
    deleteSuccess: `Account deleted successfully!`,
    deleteError: `An error occurred while deleting your account. Please try again or contact our Support.`,
    deleteProfileHeader: `Delete Your Account`,
    deleteProfileSubHeader: `Deleting your account will permanently erase all your data and generated portraits. Please note that this action is irreversible, and you will lose access to your account along with any remaining coins.`,
  },
  pricing: {
    free: `Free`,
    unlimited: `Unlimited`,
    noDownloads: `You have no Downloads left.`,
    noDownloadsDescription: `Remove this ugly text with unlimited downloads.`,
    youHaveXXDownloadsLeft: `You have {{downloadsAmount}} Downloads left.`,
    unlockUnlimitedDownloads: `Unlock unlimited downloads`,
    downloadsSingular: `{{downloadsAmount}} Download`,
    downloadsPlural: `{{downloadsAmount}} Downloads`,
    coinsSingular: `{{coinAmount}} Coin`,
    coinsPlural: `{{coinAmount}} Coins`,
    coinsLeftSingular: `You have {{coinAmount}} Coin left.`,
    coinsLeftPlural: `You have {{coinAmount}} Coins left.`,
    noCoins: `You have no Coins left.`,
    noCoinsMessage: `You need at least {{coinAmount}} coins to generate the next set of images. Please add more coins to continue.`,
    trainingsSingular: `{{trainingsAmount}} Trainings`,
    trainingsPlural: `{{trainingsAmount}} Trainings`,
    trainingsLeftSingular: `You have {{trainingsAmount}} Trainings left.`,
    trainingsLeftPlural: `You have {{trainingsAmount}} Trainings left.`,
    noTrainings: `You have no Trainings left.`,
    ohNo: `Oh no!`,
    noDownloadsUnicorn: `Free downloads are like unicorns now. Get unlimited ones with a portrait package!`,
    noDownloadsUsedUp: `You have used up all your free downloads :(`,
    discoverPortraitPackages: `Discover our Portrait Packages`,
    imageGenerations: `{{imageGenerationsAmount}} Image Generations`,
    trainingsNewCharacterSingular: `{{trainingsAmount}} New Character`,
    trainingsNewCharacterPlural: `{{trainingsAmount}} New Characters`,
  },
  purchaseOptions: {
    imageGenerationsPerDay: `{{imageGenerationsAmount}} Image Generations per Day`,
    oneTimePackages: `One-Time Packages`,
    subscriptionPackages: `Monthly Subscription`,
    subscriptionCasualPack: `Casual Pack`,
    subscriptionProfessionalPack: `Professional Pack`,
    subscriptionCrazyPack: `Crazy Pack`,
    testPack: `Test Pack`,
    payWithAllPays: `Pay with AllPays 🇮🇷 پرداخت`,
    help: `Help`,
    cryptoPaymentInquiriesTitle: `Crypto Payment Inquiries`,
    cryptoPaymentInquiriesDescription: `We're always striving to improve your experience! If you encounter any issues, have feedback, or experience problems with crypto payments or anything else, please don't hesitate to reach out. Your input helps us make things better for everyone.`,
    cryptoButtonText: `Crypto`,
    stripeButtonText: `Buy with Card, Apple Pay, Google Pay, PayPal etc.`,
    generateUpToXPortraits: `Generate up to {{coinAmount}} Portraits`,
    commercialUse: `Commercial use`,
    unlimitedDownloads: `Unlimited Downloads`,
    instantAccess: `Instant Access`,
    purchase: `Get the Package`,
    headline: `Ready to create the best portraits of your life?`,
    desktopDescription: `Unleash a gallery of incredible portraits, starting now.`,
    mobileDescription: `Unleash a gallery of incredible portraits, starting now.`,
    blackFridayDescription: `Black Friday Special: We double your coins!`,
    blackFridayBefore: `BEFORE`,
    blackFridayAfter: `BLACK FRIDAY`,
    starterPack: `Starter Pack`,
    proPack: `Pro Pack`,
    enthusiastPack: `Enthusiast Pack`,
    betaPack: `Beta Pack`,
    starterPackDescription: `Kickstart your AI portraits! Perfect for beginners with plenty of pictures to create stunning styles.`,
    proPackDescription: `Unleash your creativity with the Pro Pack. Tons of portraits for limitless AI portraits.`,
    enthusiastPackDescription: `Dive deep with the Enthusiast Pack. Loads of portraits for crafting mesmerizing portraits.`,
    betaPackDescription: `Step into the future of AI portrait creation with our BETA Pack. Enjoy a generous coin allocation, free for our beta testers, to produce extraordinary images without limits.`,
    freeTraining: `{{freeTrainingsOnOrder}} Free Training`,
    freeTrainings: `{{freeTrainingsOnOrder}} Free Trainings`,
    popular: `Popular`,
    coinPackageSuccessfullyBooked: `You have successfully booked your coin package.`,
    coinPackageBookingError: `An error occurred while booking your coin package. Please try again.`,
    packageOverviewHeadline: `Discover our Photoshoot Packages`,
    packageOverviewSubHeadline: `Create exquisite portraits that will impress your friends and enhance your online profile. Our affordable photoshoot packages make it easy. :)`,
    errorUnknown: `An unknown error occurred while verifying your purchase. Please try again.`,
    paymentProcessing: `Payment Processing...`,
    portraitAmountSingular: `{{coinAmount}} Portrait`,
    portraitAmountPlural: `{{coinAmount}} Portraits`,
    trustIndicator1Title: 'Secure Payments',
    trustIndicator1Description: '256-bit SSL encryption for all transactions',
    trustIndicator2Title: 'Money-Back Guarantee',
    trustIndicator2Description: 'No questions asked refund policy',
    trustIndicator3Title: '24/7 Support',
    trustIndicator3Description: 'Get help anytime via chat or email',
    characterTrainingTitle: `Character Training`,
    characterTrainingInfo: `Create a unique AI model based on your photos. This allows the AI to learn your specific features for more personalized portraits.`,
    trustBadges: {
      oneTimePurchase: {
        title: `One-time Purchase`,
        description: `No recurring fees`,
      },
      securePayment: {
        title: `Secure Payment`,
        description: `SSL encrypted`,
      },
      instantAccess: {
        title: `Instant Access`,
        description: `Start creating immediately`,
      },
      neverSubscribe: {
        title: `Never Subscribe`,
        description: `We hate subscriptions too`,
      },
    },
    welcomeOffer: {
      title: `Welcome Offer`,
      subtitle: `Get Double Portraits`,
    },
    description: `Start creating stunning AI portraits instantly and transform your photos into works of art. No hassles, just pure creativity.`,
  },
  pictureSetInTraining: {
    title: `Training in Progress`,
    subTitle: `Once the training completes, typically within an hour, you'll be ready to create AI-generated portraits. Enjoy the experience!`,
    progressSingular: `{{progress}}% completed`,
    progressPlural: `{{progress}}% completed`,
    searchingForServer: `Preparing for the next training phase...`,
  },
  imageUploader: {
    characterName: `Character Name`,
    gender: `Gender`,
    selectGender: `Select Gender`,
    male: `Male`,
    female: `Female`,
    characterNamePlaceholder: `Enter the character name`,
    title: `Create a new Character`,
    createCharacter: `Create Character`,
    subTitle: `Please Upload between {{minPictures}} and {{maxPictures}} Pictures.`,
    uploadOnly1Person: 'Upload images featuring the same individual. Each image should include only one person. Choose your best pictures.',
    afterUploadInfo: `We'll train an AI model just for you. Once ready, create endless portraits!`,
    uploadPrivateInfo: `Your photos are private and secure. We will not share them with anyone.`,
    explainWhySoManyPictures: `More photos = better results! We train an AI model exclusively for you that learns your unique features from every angle. The more photos you provide, the more natural and authentic your AI portraits will look. It's like teaching the AI to truly know you! 🎯`,
    generationPromptMin: `You must upload at least {{minUserPictures}} picture(s).`,
    generationPromptMax: `You can upload a maximum of {{maxUserPictures}} pictures.`,
    helpButton: `Help me! Which pictures I need to choose?`,
    goodPictures: `Good Pictures`,
    goodPicturesDescription: `We need close-up pictures of the same person. Different backgrounds, angles, clothing, and expressions are beneficial.`,
    badPictures: `Bad Pictures`,
    badPicturesDescription: `Group photos, other people or pets, hands on face, small/obscured faces, nudes, poor lighting, repetitive images, or children.`,
    characterNameRequired: `Character name is required.`,
    characterNameMax: `Character name must be less than 20 characters.`,
    genderRequired: `Gender is required.`,
  },
  imageUploaderForm: {
    selectFiles: `Select your pictures`,
    dragAndDrop: `Drag and drop images here to upload.`,
    or: `or`,
    processing: `Processing Image | Please wait...`,
    clickHere: `Click here to choose Your Pictures`,
    pressHere: `Press here to choose Your Pictures`,
  },
  imagePreviewAndSave: {
    pleaseChooseBetween: `Pick {{min}} - {{max}} photos of yourself.`,
    selectYourPictures: `Select your photos with a focus on clarity and variety. Aim for around 70% close-up shots of your face from different angles and 30% that include half or full body.`,
    successfulChosen: `You have chosen {{count}} photos.`,
    pleaseChose: `Please choose {{count}} more photo(s) to proceed.`,
    maxLimit: `Limit is {{count}} photos. Please remove {{countRemove}} excess photo(s).`,
    zoomInfo: `If your photo is large enough, use the zoom slider to focus on your face. Click "Save my photos" when ready to proceed.`,
    zoomInfoMobile: `If your picture is large enough, use two fingers to pinch and zoom in on your face and also 2 fingers to move the picture around to center your face. Click "Save my Pictures" when ready to proceed.`,
    saveMyPictures: `Save my Pictures`,
    zoom: `Zoom in / out`,
    errors: {
      '406': 'No files were uploaded.',
      '400': 'Not all files were uploaded. please try again.',
    },
  },
  menu: {
    create: `Create`,
    yourPhotoShooting: `Your Photo Shooting`,
    yourPhotoShootingDescription: `Ready-made professional styles`,
    yourPhotoShootingSubDescription: `Perfect for LinkedIn, Business & Social Media`,
    createCustom: `Create Custom`,
    createCustomDescription: `AI-powered portrait creation with our AI Helper`,
    createCustomSubDescription: `Unlimited creative possibilities`,
    myGallery: `My Gallery`,
    myGalleryDescription: `Your AI Portrait Collection`,
    myGallerySubDescription: `Manage your Pictures`,
    createFreeStylePhotoShooting: `Free Style Photo Shooting`,
    createFreeStylePhotoShootingDescription: `Create your own style`,
    createFreeStylePhotoShootingSubDescription: `Unlimited creative possibilities`,
    aiPortraitChat: 'AI Portrait Chat',
    aiPortraitChatDescription: 'Chat with AI to Create Your Portraits',
    aiPortraitChatSubDescription: 'Have a conversation with our AI to create personalized portrait photos',
    aiImageGenerator: 'AI Image Generator',
    aiImageGeneratorDescription: 'Create Any Image You Imagine',
    aiImageGeneratorSubDescription: 'Generate custom AI images from your text descriptions',
    myVideosDescription: 'Your AI Portrait Videos',
    myVideosSubDescription: 'Manage your Videos',
    coins: `Coins`,
    characters: `Characters`,
    videos: `Videos`,
    chat: `Chat`,
    home: `Home`,
    theBeginning: `The Beginning`,
    uploadTraining: `Upload & Training`,
    discover: `Discover`,
    getCoins: `Get Coins`,
    settings: `Settings`,
    help: `Help`,
    logout: `Logout`,
    signIn: `Sign In`,
    showMore: `Show More`,
    showLess: `Show Less`,
    myProfile: `My Characters`,
    exploreAll: `Explore All`,
    myPictures: `My Pictures`,
    photoshooting: `Photo Shoot`,
    artistic: `Fantasy Art`,
    myStyle: `My Style`,
    addCoins: `Add Coins`,
    freeStyle: `Freestyle`,
    myVideos: `My Videos`,
    myStories: `My Stories`,
    more: `More`,
  },
  categories: {
    anime_gaming: 'Gaming & Anime',
    art_styles: 'Art Styles',
    cartoon_animation: 'Cartoon & Animation',
    contemporary_figures: 'Contemporary Figures',
    fantasy: 'Fantasy',
    film_media_inspired: 'Film & Media Inspired',
    historical_cultural: 'Historical & Cultural',
    historical_figures: 'Historical Figures',
    horror_thriller: 'Horror & Thriller',
    industrial_technology: 'Industrial & Technology',
    literature_folklore: 'Literature & Folklore',
    movie_inspired: 'Movie Inspired',
    mythological_creatures: 'Mythological Creatures',
    nature_environment: 'Nature & Environment',
    regional_cultural: 'Regional & Cultural',
    sci_fi_futuristic: 'Sci-fi & Futuristic',
    test_data: 'Test Data',
    business: 'Business',
    freestyle: 'Freestyle',
    black_and_white: 'Black and White',
    city: 'City',
    fashion: 'Fashion',
    outdoor: 'Outdoor',
    street_photography: 'Street Photography',
    studio_photoshooting: 'Studio Photoshooting',
    party: 'Party',
    hairstyles: 'Hairstyles',
    halloween: 'Halloween',
    funny: 'Funny',
    nature: 'Nature',
    aiDating: 'AI Dating',
    boudoir: 'Boudoir',
    headshots: 'Headshots',
    carsAndBikes: 'Cars & Bikes',
  },
  portraitSetsName: {
    straightforwardProfessional: `Straightforward Professional`,
    thoughtLeader: `Thought Leader`,
    dynamicEntrepreneur: `Dynamic Entrepreneur`,
    innovatorAtWork: `Innovator at Work`,
    teamLeader: `Team Leader`,
    contemporaryStage: `Contemporary Stage`,
    bustlingInnovationLab: `Bustling Innovation Lab`,
    conferenceRoom: `Conference Room`,
    polishedGlassTable: `Polished Glass Table`,
    openPlanOffice: `Open-Plan Office`,
    classicOffice: `Classic Office`,
    professionalStage: `Professional Stage`,
    brainstormingSpace: `Brainstorming Space`,
    chicOfficeFrame: `Chic Office Frame`,
    sunlitOfficeLibrary: `Sunlit Office Library`,
    urbanRooftop: `Urban Rooftop`,
    worldMap: `World Map`,
    texturedGrayBackdrop: `Textured Gray Backdrop`,
    modernCoWorking: `Modern Co-Working`,
    modernOffice: `Modern Office`,
    elegantConsultant: `Elegant Consultant`,
    executiveCeoPortrait: `Executive CEO Portrait`,
    actorActressHeadshot: `Actor/Actress Headshot`,
    formalPortrait: `Formal Portrait`,
    crispStudioHeadshot: `Crisp Studio Headshot`,
    smilingCloseUp: `Smiling Close Up`,
    indianScout: `Indian Scout`,
    harleyDavidson: `Harley-Davidson`,
    pontiacFirebird: `Pontiac Firebird`,
    dodgeCharger: `Dodge Charger`,
    fordMustang: `Ford Mustang`,
    mercedesBenz300SL: `Mercedes-Benz 300SL`,
    porsche356: `Porsche 356`,
    cadillacEldorado: `Cadillac Eldorado`,
    jaguarEType: `Jaguar E-Type`,
    volkswagenBeetle: `Volkswagen Beetle`,
    classicConvertible: `Classic Convertible`,
    classicElegance: `Classic Elegance`,
    vintageTriumph: `Vintage Triumph`,
    harleyDavidsonSidecar: `Harley-Davidson Sidecar`,
    vintageJeep: `Vintage Jeep`,
    rainyDay: `Rainy Day`,
    vintageElegance: `Vintage Elegance`,
    sleekSportsCar: `Sleek Sports Car`,
    jessicaKobeissiInspired: `Jessica Kobeissi Inspired`,
    alexStoddardInspired: `Alex Stoddard Inspired`,
    brandoWoelfelInspired: `Brando Woelfel Inspired`,
    tamaraWilliamsInspired: `Tamara Williams Inspired`,
    daniDiamondInspired: `Dani Diamond Inspired`,
    johnKeatleyInspired: `John Keatley Inspired`,
    dylanPatrickInspired: `Dylan Patrick Inspired`,
    dreamlikeGlow: `Dreamlike Glow`,
    cinematicBlackAndWhite: `Cinematic Black and White`,
    softGoldenGlow: `Soft Golden Glow`,
    ultraClean: `Ultra-Clean`,
    brianMarcusInspired: `Brian Marcus Inspired`,
    benLambertyInspired: `Ben Lamberty Inspired`,
    karshOfOttawa: `Karsh of Ottawa`,
    timothyGreenfieldSandersInspired: `Timothy Greenfield-Sanders Inspired`,
    lindsayAdlerInspired: `Lindsay Adler Inspired`,
    rembrandtLighting: `Rembrandt Lighting`,
    ivanWeissInspired: `Ivan Weiss Inspired`,
    minimalistStudio: `Minimalist Studio`,
    joelGrimesInspired: `Joel Grimes Inspired`,
    davidSlaterInspired: `David Slater Inspired`,
    bradStantonInspired: `Brad Stanton Inspired`,
    barbaraPeacockInspired: `Barbara Peacock Inspired`,
    platonInspired: `Platon Inspired`,
    danWintersInspired: `Dan Winters Inspired`,
    martinSchoellerInspired: `Martin Schoeller Inspired`,
    emilySotoInspired: `Emily Soto Inspired`,
    joeMcNallyInspired: `Joe McNally Inspired`,
    rankinInspired: `Rankin Inspired`,
    peterHurleyInspired: `Peter Hurley Inspired`,
    annieLeibovitzInspired: `Annie Leibovitz Inspired`,
    moonlitElk: `Moonlit Elk`,
    northernLightsSerpent: `Northern Lights Serpent`,
    frostGriffin: `Frost Griffin`,
    paleFoxes: `Pale Foxes`,
    crystalOwl: `Crystal Owl`,
    frostBear: `Frost Bear`,
    celestialElk: `Celestial Elk`,
    frostLynx: `Frost Lynx`,
    paleSerpent: `Pale Serpent`,
    winterGriffin: `Winter Griffin`,
    ghostPanther: `Ghost Panther`,
    moonlitMystic: `Moonlit Mystic`,
    frostTiger: `Frost Tiger`,
    guardianOfTheWhiteWolf: `Guardian of the White Wolf`,
    guardianOfTheCrystalWolf: `Guardian of the Crystal Wolf`,
    lionOfTheSun: `Lion of the Sun`,
    gildedSunWarrior: `Gilded Sun Warrior`,
    forestOfEchoes: `Forest of Echoes`,
    skyborneSovereign: `Skyborne Sovereign`,
    blossomSovereign: `Blossom Sovereign`,
    stormCaller: `Storm Caller`,
    phoenixAscension: `Phoenix Ascension`,
    shadowsAndLace: `Shadows and Lace`,
    goldenSilhouettes: `Golden Silhouettes`,
    bathtubBliss: `Bathtub Bliss`,
    opulentLingerie: `Opulent Lingerie`,
    hidden_garden: `Hidden Garden`,
    morning_mist: `Morning Mist`,
    baroquePaintings: `Baroque Paintings`,
    nostalgic: `Nostalgic`,
    lightFabrics: `Light Fabrics`,
    impressionistPaintings: `Impressionist Paintings`,
    moodyBaroque: `Moody Baroque`,
    steampunkFantasy: `Steampunk Fantasy`,
    cyberpunkRacer: `Cyberpunk Racer`,
    cherryCannon: `Cherry Cannon`,
    studioGhibli: `Studio Ghibli`,
    looneyTunes: `Looney Tunes`,
    rickAndMorty: `Rick and Morty`,
    adventureTime: `Adventure Time`,
    ironGiant: `Iron Giant`,
    flintstones: `Flintstones`,
    metropolitanIcon: `Metropolitan Icon`,
    futuristicKnight: `Futuristic Knight`,
    modernDayPoet: `Modern Day Poet`,
    modernAristocrat: `Modern Aristocrat`,
    exposedBrickWall: `Exposed Brick Wall`,
    reimaginedJazz: `Reimagined Jazz`,
    postApocalypticWanderer: `Post-Apocalyptic Wanderer`,
    modernGladiator: `Modern Gladiator`,
    industrialEdge: `Industrial Edge`,
    runwayElegance: `Runway Elegance`,
    minimalistMonochrome: `Minimalist Monochrome`,
    neonNoir: `Neon Noir`,
    rooftopGlamour: `Rooftop Glamour`,
    abstractStudio: `Abstract Studio`,
    parisianStreetChic: `Parisian Street Chic`,
    urbanPlayground: `Urban Playground`,
    darkVelvet: `Dark Velvet`,
    curtainsAndSunlight: `Curtains and Sunlight`,
    deepRed: `Deep Red`,
    vintageCandles: `Vintage Candles`,
    fluffyWhite: `Fluffy White`,
    softlyLitBedroom: `Softly Lit Bedroom`,
    luxuriousSilk: `Luxurious Silk`,
    vintageBed: `Vintage Bed`,
    champagne: `Champagne`,
    sunriseBalcony: `Sunrise Balcony`,
    goldenHourBeach: `Golden Hour Beach`,
    rooftopTerrace: `Rooftop Terrace`,
    coffeeShop: `Coffee Shop`,
    forestTrail: `Forest Trail`,
    bookstore: `Bookstore`,
    sunnyPark: `Sunny Park`,
    gallery: `Gallery`,
    farmersMarket: `Farmers Market`,
    streetlights: `Streetlights`,
    vineyard: `Vineyard`,
    urbanLounge: `Urban Lounge`,
    calmLake: `Calm Lake`,
    rusticCabin: `Rustic Cabin`,
    beachTwilight: `Beach Twilight`,
    sunnyParkWithDog: `Sunny Park with Dog`,
    couchWithCat: `Couch with Cat`,
    ranchWithHorse: `Ranch with Horse`,
    lakeWithDucks: `Lake with Ducks`,
    puppyInPark: `Puppy in Park`,
    tropicalBeach: `Tropical Beach`,
    gym: `Gym`,
    t_rex: `T-Rex`,
    bear: `Bear`,
    mug_shot: `Mugshot`,
    emergency_room: `Emergancy Room`,
    roller_coaster: `Roller Coaster`,
    testportraits: `Testportraits`,
    skydive: `Skydive`,
    clown: `Clown`,
    fallen_angel: `Fallen Angel`,
    femme_fatale_zombie: `Femme Fatale Zombie`,
    seductress: `Seducress`,
    demon: `Demon`,
    werewolf: `Werewolf`,
    undead_king: `Undead King`,
    vampire_hunter: `Vampire Hunter`,
    undead_pirate: `Undead Pirate`,
    flower_wall: `Flower Wall`,
    pastel_balloons: `Pastel Balloons`,
    paper_flowers: `Paper Flowers`,
    solo_leveling: `Solo Leveling`,
    my_hero_academia: `My Hero Academia`,
    magic_and_muscles: `Magic and Muscles`,
    elden_ring: `Elden Ring`,
    final_fantasy: `Final Fantasy`,
    legend_of_zelda: `Legend of Zelda`,
    ancient_forest: `Ancient Forest`,
    ocean_harmony: `Ocean Harmony`,
    savannah_stroll: `Savannah Stroll`,
    arctic_embrace: `Arctic Embrace`,
    falcons_trust: `Falcons Trust`,
    snow_owl: `Snow Owl`,
    fox: `Fox`,
    rolling_prairies: `Rolling Prairies`,
    cliff: `Cliff`,
    pulpit: `Pulpit`,
    sky_dances: `Sky Dances`,
    lavender: `Lavender`,
    sport_car: `Sport Car`,
    business: `Business`,
    renaissance_art: `Renaissance Art`,
    industrial_revolution: `Industrial revolution`,
    science_fiction_noir: `Science fiction noir`,
    lush_rainforest: `Lush rainforest`,
    underwater_atlantis: `Underwater Atlantis`,
    wild_west: `Wild West`,
    tribal_art: `Tribal art`,
    baroque_period: `Baroque period`,
    vikings: `Vikings`,
    expressionism: `Expressionism`,
    pixar_esque: `Pixar-esque`,
    manga_anime_style: `Manga / Anime Style`,
    elven_style: `Elven Style`,
    post_apocalyptic: `Post Apocalyptic`,
    mad_max: `Mad Max`,
    intergalactic_explorer: `Intergalactic Explorer`,
    zombie_horror: `Zombie Horror`,
    winter_wonderland: `Winter Wonderland`,
    post_modern_deconstructivism: `Post-modern Deconstructivism`,
    ukrainian_folk: `Ukrainian Folk`,
    harry_potter: `Harry Potter`,
    spartan_300_movie: `Spartan 300 Movie`,
    fire_battle_mage: `Fire Battle Mage`,
    ice_battle_mage: `Ice Battle Mage`,
    water_battle_mage: `Water Battle Mage`,
    storm_battle_mage: `Storm Battle Mage`,
    in_the_rain: `In the Rain`,
    as_hobbit: `As Hobbit`,
    lord_of_the_rings: `Lord of the Rings`,
    military_drone: `Military Drone`,
    orc: `Orc`,
    kazakhs: `Kazakhs`,
    sherlock_holmes: `Sherlock Holmes`,
    gandalf: `Gandalf`,
    darth_vader: `Darth Vader`,
    leonardo_da_vinci: `Leonardo da Vinci`,
    cleopatra: `Cleopatra`,
    jack_sparrow: `Jack Sparrow`,
    frankenstein: `Frankenstein`,
    dr_jekyll: `Dr. Jekyll`,
    captain_kirk: `Captain Kirk`,
    albert_einstein: `Albert Einstein`,
    nikola_tesla: `Nikola Tesla`,
    king_arthur: `King Arthur`,
    the_joker: `The Joker`,
    iron_man: `Iron Man`,
    thor: `Thor`,
    harry_houdini: `Harry Houdini`,
    yoda: `Yoda`,
    joan_of_arc: `Joan of Arc`,
    cheshire_cat: `Cheshire Cat`,
    dracula: `Dracula`,
    phoenix: `Phoenix`,
    cthulhu: `Cthulhu`,
    minotaur: `Minotaur`,
    medusa: `Medusa`,
    fenrir: `Fenrir`,
    kitsune: `Kitsune`,
    ganesha: `Ganesha`,
    anubis: `Anubis`,
    quetzalcoatl: `Quetzalcoatl`,
    cossack: `Cossack`,
    special_forces: `Special Forces`,
    dark_elf: `Dark Elf`,
    gollum: `Gollum`,
    wolverine: `Wolverine`,
    predator: `Predator`,
    mummy: `Mummy`,
    zombie: `Zombie`,
    mermaid_merman: `Mermaid / Merman`,
    dragon: `Dragon`,
    siren: `Siren`,
    centaur: `Centaur`,
    fairy: `Fairy`,
    chimera: `Chimera`,
    troll: `Troll`,
    banshee: `Banshee`,
    griffin: `Griffin`,
    cyclops: `Cyclops`,
    nymph: `Nymph`,
    yeti: `Yeti`,
    djinn: `Djinn`,
    thunderbird: `Thunderbird`,
    naga: `Naga`,
    garuda: `Garuda`,
    yaksha: `Yaksha`,
    apsara: `Apsara`,
    kinnara: `Kinnara`,
    rakshasa: `Rakshasa`,
    sketch: `Sketch`,
    anime_3d: `Anime 3D`,
    robotic_avatar: `Robotic Avatar`,
    fantasy_elf_3d: `Fantasy Elf 3D`,
    chibi_avatar: `Chibi Avatar`,
    voxel_game_character: `Voxel Game Character`,
    cybernetic_outlaw: `Cybernetic Outlaw`,
    neo_tokyo_resident: `Neo-Tokyo Resident`,
    hacker_persona: `Hacker Persona`,
    corp_executive: `Corp Executive`,
    cyberpunk_2077_inspired: `Cyberpunk 2077 Inspired`,
    freestyle: `Freestyle`,
    freddy_krueger: `Freddy Krueger`,
    LurkingShape: `Lurking Shape`,
    AbyssalEntity: `Abyssal Entity`,
    ShadowWhisperer: `Shadow Whisperer`,
    artificial_intelligence: `Artificial Intelligence`,
    quantum_computing: `Quantum Computing`,
    DataSculptor: `Data Sculptor`,
    SiliconVisionary: `Silicon Visionary`,
    CyberneticVanguard: `Cybernetic Vanguard`,
    SpringHealer: `Spring Healer`,
    MysticSpringVisionary: `Mystic Spring Visionary`,
    summer_mountains: `Summer Mountains`,
    summer_shooting: `Summer Photo Shooting`,
    spring_rejuvenation: `Spring Rejuvenation`,
    post_apocalyptic_survivor: `Post-Apocalyptic Survivor`,
    cyberpunk_vigilante: `Cyberpunk Vigilante`,
    gothic_horror_enigma: `Gothic Horror Enigma`,
    action_thriller_protagonist: `Action Thriller Protagonist`,
    vintage_hollywood_starlet: `Vintage Hollywood Starlet`,
    sci_fi_odyssey_commander: `Sci-Fi Odyssey Commander`,
    neo_noir_sleuth: `Neo-Noir Sleuth`,
    pirate_lagoon_quest: `Pirate Lagoon Quest`,
    cosmic_cadet_chronicles: `Cosmic Cadet Chronicles`,
    toontown_adventurer: `Toontown Adventurer`,
    pixel_pals: `Pixel Pals`,
    guardian_of_the_ancients: `Guardian of the Ancients`,
    mecha_pilot_maverick: `Mecha Pilot Maverick`,
    cyberpunk_samurai: `Cyberpunk Samurai`,
    toon_town_mayor: `Toon Town Mayor`,
    galactic_guardian: `Galactic Guardian`,
    maori_warrior: `Maori Warrior`,
    venetian_virtuoso: `Venetian Virtuoso`,
    arctic_sage: `Arctic Sage`,
    desert_guardian: `Desert Guardian`,
    highland_seer: `Highland Seer`,
    mystic_nomad: `Mystic Nomad`,
    office_black_and_white: `Office Black and White`,
    office_pro5: `Office Professional 5`,
    office_pro4: `Office Professional 4`,
    office_pro3: `Office Professional 3`,
    office_pro2: `Office Professional 2`,
    office_pro: `Office Professional 1`,
    hr: `HR Professional`,
    marketing_maven: `Marketing Maven`,
    industry_leader: `Industry Leader`,
    corporate_executive: `Corporate Executive`,
    financial_analyst: `Financial Analyst`,
    lawyer: `Lawyer`,
    legal_expert: `Legal Expert`,
    tech_innovator: `Tech Innovator`,
    black_and_white_window: `Black and White Window`,
    light_and_shadow: `Light and Shadow`,
    dystopian_water: `Dystopian Water`,
    nouveau_floral: `Nouveau Floral`,
    geometric_shapes: `Geometric Shapes`,
    smoke_sculpture: `Smoke Sculpture`,
    window_painting: `Window Painting`,
    venetian_aurorapunk: `Venetian Aurora Punk`,
    art_nouveau_drawing: `Art Nouveau Drawing`,
    classic_hollywood: `Classic Hollywood`,
    timeless_close_up: `Timeless Close-Up`,
    architectural_ines: `Architectural Ines`,
    rainy_day_reflections: `Rainy Day Reflections`,
    dramatic_light: `Dramatic Light`,
    abstract_eflection: `Abstract Reflection`,
    garden_archway: `Garden Archway`,
    snowy_woodlands: `Snowy Woodlands`,
    city_rooftop_at_night: `City Rooftop at Night`,
    vintage_train: `Vintage Train`,
    library: `Library`,
    rainy_window_reflection: `Rainy Window Reflection`,
    candles: `Candles`,
    modern_space: `Modern Space`,
    double_exposure: `Double Exposure`,
    soft_focus: `Soft Focus`,
    low_key_light: `Low-Key Light`,
    shadowplay: `Shadowplay`,
    richard_avedon_inspired: `Richard Avedon Inspired`,
    irving_penn_inspired: `Irving Penn Inspired`,
    helmut_newton_inspired: `Helmut Newton Inspired`,
    new_york_city: `New York City`,
    paris: `Paris`,
    tokyo: `Tokyo`,
    rome: `Rome`,
    london: `London`,
    dubai: `Dubai`,
    sydney: `Sydney`,
    san_francisco: `San Francisco`,
    barcelona: `Barcelona`,
    berlin: `Berlin`,
    annie_leibovitz_style: `Annie Leibovitz Style`,
    richard_avedon_style: `Richard Avedon Style`,
    helmut_newton_style: `Helmut Newton Style`,
    david_lachapelle_style: `David LaChapelle Style`,
    platon_style: `Platon Style`,
    mario_testino_style: `Mario Testino Style`,
    peter_lindbergh_style: `Peter Lindbergh Style`,
    cecil_beaton_style: `Cecil Beaton Style`,
    annie_spratt_style: `Annie Spratt Style`,
    herb_ritts_style: `Herb Ritts Style`,
    tim_walker_style: `Tim Walker Style`,
    rankin_style: `Rankin Style`,
    juergen_teller_style: `Juergen Teller Style`,
    patrick_demarchelier_style: `Patrick Demarchelier Style`,
    ellen_von_unwerth_style: `Ellen von Unwerth Style`,
    nick_knight_style: `Nick Knight Style`,
    glamorous_red_carpet_party: `Glamorous Red Carpet Party`,
    great_gatsby_party: `Great Gatsby Party`,
    masquerade_ball: `Masquerade Ball`,
    disco_fever_party: `Disco Fever Party`,
    halloween_costume_party: `Halloween Costume Party`,
    new_years_eve_party: `New Year's Eve Party`,
    tropical_luau_party: `Tropical Luau Party`,
    retro_80s_party: `80s Retro Party`,
    pool_party: `Pool Party`,
    festival_of_colors: `Festival of Colors`,
    carnival_parade: `Carnival Parade`,
    winter_wonderland_party: `Winter Wonderland Party`,
    pyjama_party: `Pyjama Party`,
    rock_n_roll_party: `Rock 'n' Roll Party`,
    tea_party: `Tea Party`,
    starlit_rooftop_party: `Starlit Rooftop Party`,
    ansel_adams_style: `Ansel Adams Style`,
    sunset_beach: `Sunset Beach`,
    urban_rooftop: `Urban Rooftop`,
    countryside_field: `Countryside Field`,
    urban_street: `Urban Street`,
    lakefront_serenity: `Lakefront Serenity`,
    botanical_garden: `Botanical Garden`,
    desert_dunes: `Desert Dunes`,
    cliffside_view: `Cliffside View`,
    rustic_barn: `Rustic Barn`,
    snow_covered_forest: `Snow-Covered Forest`,
    riverside_walk: `Riverside Walk`,
    canyon_exploration: `Canyon Exploration`,
    country_road: `Country Road`,
    autumn_leaves: `Autumn Leaves`,
    waterfall_mist: `Waterfall Mist`,
    coastal_cliffs: `Coastal Cliffs`,
    garden_path: `Garden Path`,
    helmut_newton_outdoor: `Helmut Newton Outdoor`,
    richard_avedon_indoor: `Richard Avedon Indoor`,
    mario_testino_outdoor: `Mario Testino Outdoor`,
    steven_meisel_indoor: `Steven Meisel Indoor`,
    ellen_von_unwerth_indoor: `Ellen von Unwerth Indoor`,
    bruce_weber_outdoor: `Bruce Weber Outdoor`,
    peter_lindbergh_indoor: `Peter Lindbergh Indoor`,
    tim_walker_outdoor: `Tim Walker Outdoor`,
    paolo_roversi_indoor: `Paolo Roversi Indoor`,
    herb_ritts_outdoor: `Herb Ritts Outdoor`,
    david_lachapelle_indoor: `David LaChapelle Indoor`,
    irving_penn_indoor: `Irving Penn Indoor`,
    rankin_outdoor: `Rankin Outdoor`,
    juergen_teller_indoor: `Juergen Teller Indoor`,
    solve_sundsbo_outdoor: `Sølve Sundsbø Outdoor`,
    guy_bourdin_indoor: `Guy Bourdin Indoor`,
    patrick_demarchelier_outdoor: `Patrick Demarchelier Outdoor`,
    craig_mcdean_indoor: `Craig McDean Indoor`,
    cartier_bresson_style: `Cartier-Bresson Style`,
    vivian_maier_style: `Vivian Maier Style`,
    garry_winogrand_style: `Garry Winogrand Style`,
    saul_leiter_style: `Saul Leiter Style`,
    robert_frank_style: `Robert Frank Style`,
    alex_webb_style: `Alex Webb Style`,
    martin_parr_style: `Martin Parr Style`,
    urban_reflections: `Urban Reflections`,
    neon_nights: `Neon Nights`,
    subway_stories: `Subway Stories`,
    urban_graffiti: `Urban Graffiti`,
    window_views: `Window Views`,
    urban_symmetry: `Urban Symmetry`,
    rainy_day: `Rainy Day`,
    street_corners: `Street Corners`,
    buzz_cut: 'Buzz Cut',
    crew_cut: 'Crew Cut',
    undercut: 'Undercut',
    pompadour: 'Pompadour',
    quiff: 'Quiff',
    fade: 'Fade',
    french_crop: 'French Crop',
    slicked_back: 'Slicked Back',
    man_bun: 'Man Bun',
    textured_fringe: 'Textured Fringe',
    flowing_waves: 'Flowing Waves',
    shoulder_length_layers: 'Shoulder Length Layers',
    messy_long_hair: 'Messy Long Hair',
    side_part: 'Side Part',
    ivy_league: 'Ivy League',
    classic_taper: 'Classic Taper',
    mullet: 'Mullet',
    mohawk: 'Mohawk',
    bowl_cut: 'Bowl Cut',
    long_wavy_hair: 'Long Wavy Hair',
    bob_cut: 'Bob Cut',
    pixie_cut: 'Pixie Cut',
    sleek_straight_hair: 'Sleek Straight Hair',
    curly_afro: 'Curly Afro',
    high_ponytail: 'High Ponytail',
    braided_updo: 'Braided Updo',
    beach_waves: 'Beach Waves',
    half_up_half_down: 'Half Up Half Down',
    fishtail_braid: 'Fishtail Braid',
    layered_bob: 'Layered Bob',
    low_bun: 'Low Bun',
    curtain_bangs: 'Curtain Bangs',
    messy_bun: 'Messy Bun',
    shag_cut: 'Shag Cut',
    french_twist: 'French Twist',
    voluminous_curls: 'Voluminous Curls',
    straight_bangs: 'Straight Bangs',
    crown_braid: 'Crown Braid',
    waterfall_braid: 'Waterfall Braid',
  },
  generate: {
    title: `Customize & Generate Your Portrait`,
    description: `Customize your portrait by adjusting attributes such as hair color, expression, and lighting. Just write down your preferences.`,
    addYourStyles: `Add your styles`,
    pressCTRL: `You can press SHIFT + ENTER to generate.`,
    aspectRatio: `Aspect Ratio`,
    aspectRatioOptions: {
      label7x9: `Portrait (7:9)`,
      description7x9: `Ideal for full body portraits and vertical social media posts.`,
      label1x1: `Square (1:1)`,
      description1x1: `Ideal for square images and social media posts.`,
      label4x7: `Phone (4:7)`,
      description4x7: `Good for mobile phone screens and vertical social media posts.`,
      label7x4: `Widescreen (7:4)`,
      description7x4: `Perfect choice for landscape images and widescreen displays.`,
    },
    generateNewSetTooltip: `Create your next set of images`,
    generateNewSet: `Generate new portraits`,
    errors: {
      insufficientCoins: `You don't have enough coins to generate this portrait. Please purchase more coins.`,
    },
    chooseAspectRatio: `Choose the aspect ratio that fits your requirements.`,
  },
  userRegistration: {
    form: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      birthday: 'Birthday',
      submit: 'Create account',
      validation: {
        minMaxLength: 'Should be between 2 and 50 characters',
        firstNameRequired: 'First name required',
        lastNameRequired: 'Last name required',
        emailRequired: 'Email is required',
        emailInvalid: 'Email must be a valid email address',
        passwordRequired: 'Password is required',
        passwordPattern:
          'Password must contain at least one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character (!@#$%^&*), no space, and it must be 8-32 characters long.',
        confirmPasswordRequired: 'Confirm password is required',
        passwordMatch: 'Passwords must match',
        birthdayRequired: 'Birthday is required',
        birthdayMinAge: 'You must be at least 16 years old',
      },
    },
    errors: {
      emailExists:
        "The email address you entered is already associated with an account. If this account is yours, please try logging in or use the 'Forgot Password' option to reset your password. If you believe this is an error, please contact support.",
      registrationFailed: `An error occurred during registration. Please try again later.`,
      alreadyActivated: 'This account has already been activated. Please log in.',
      activationFailed: `An error occurred during activation. Please try again later.`,
      tokenNotFound: `The token you entered is invalid. Please check the link in your email and try again.`,
    },
    successRegistration: `Your account has been successfully created. Please check your email to activate your account.`,
    successActivation: 'Your account has been successfully activated.',
    pageTitle: `Create your Account`,
    headline: `Create your Account`,
    alreadyHaveAccount: `Already have an account?`,
    signIn: `Sign In`,
    agreeTo: `By creating an account, you agree to our {{Terms and Conditions}} and {{Privacy Policy}}.`,
  },
  userLogin: {
    errors: {
      invalidCredentials: `Invalid credentials. Please try again.`,
      accountNotActivated: `Your account has not been activated yet.`,
      loginFailed: `An error occurred during login. Please try again later.`,
      permissionDenied: `You are not authorized to access this resource.`,
      oAuthRejected: `OAuth login rejected`,
    },
    signIn: `Sign In`,
    signInOrRegister: `Sign In or Register`,
    newUser: `New to myportrait.studio?`,
    createAccount: `Create an Account`,
    forgotPassword: `Forgot your password?`,
    login: `Login`,
    emailAddress: `Email Address`,
    password: `Password`,
    signInWithApple: `Sign in with Apple`,
    signInWithGoogle: `Sign in with Google`,
    signInWithFacebook: `Sign in with Facebook`,
    signInWithGithub: `Sign in with Github`,
    signInAsGuest: `Continue as Guest`,
    or: `or`,
    pageTitle: `Sign In`,
    rememberMe: `Remember Me`,
  },
  resetPassword: {
    pageTitle: `Reset your Password`,
    headline: `Forgot your password?`,
    description: `Please enter the email address associated with your account. We will send you a link to reset your password.`,
    emailAddress: `Email Address`,
    submit: `Send Reset Instructions`,
    success: `A password reset link has been sent to the email address.`,
    returnToSignIn: `Back to Sign In`,
    requestSendSuccessfully: `Request sent successfully!`,
    weHaveSentInstructions: `We sent a 6-digit code to your email.`,
    pleaseEnterCode: `Enter the code below to verify your email.`,
    newPassword: `New Password`,
    confirmNewPassword: `Confirm New Password`,
    submitNewPassword: `Update Password`,
    noCodeReceived: `Didn't receive a code?`,
    resendCode: `Resend Code`,
    codeIsRequired: 'Code is required',
    emailIsRequired: 'Email is required',
    emailMustBeValid: 'Email must be a valid email address',
    confirmPasswordIsRequired: 'Confirm password is required',
    passwordMustMatch: 'Passwords must match',
    errors: {
      notFound: `User not found`,
      invalidCode: `Invalid code. Please try again.`,
      codeExpired: `The code has expired. Please request a new one.`,
      passwordResetFailed: `An error occurred while resetting your password. Please try again later.`,
    },
    successChange: 'Your password has been successfully reset. Please log in with your new password.',
  },
  errors: {
    noResponse: `No response from server. Please check your network connection.`,
    unknown: `An unknown error occurred. Please try again later.`,
    '500': `An error occurred on the server. Please try again later.`,
    '401': `You are not authorized to access this resource. Please log in and try again.`,
    updateFailed: `An error occurred while updating. Please try again later.`,
    invalidPassword: `Invalid password. Please try again.`,
    incorrectCurrentPassword: `Incorrect current password. Please try again.`,
    newPasswordMystBeDifferent: `New password must be different from the current password.`,
  },
  success: {
    passwordChanged: `Your password has been successfully changed.`,
    saved: `Your changes have been saved.`,
    updated: `Your information has been updated.`,
  },
  home: {
    homeHeroTitleTikTok: `Hey TikTok! Ever wonder how influencers always look perfect? 🤔`,
    homeHeroTitleBusiness: `Your AI generated Business Portrait is here!`,
    homeHeroTitleHeadshots: `Your AI generated Headshots are here!`,
    homeHeroTitleDating: `From 'Meh' to Matches: AI Photos That Get You Dates`,
    homeHeroTitleArt: `Your personal Art and Fantasy Portrait is here!`,
    betaFreeTitle: `First 20 Portraits Free`,
    betaFreeDescription: `Generate stunning, unique portraits for all your favorite platforms - Instagram, Tinder, Facebook, and more. Our cutting-edge AI technology creates an endless array of portraits, and it's completely free during the BETA phase! Sign up now and start generating your own AI portraits for free!`,
    beaFreeButton: `Sign Up`,
    homeTitle: `AI-driven image generation platform - Stunning, unique portraits!`,
    homeHeroTitle: `Your Face. Your Art. Crafted by AI.`,
    homeHeroTitlePhotographer: `Stand Out Instantly with Stunning AI-Generated Portraits`,
    homeHeroDescription: `Tired of dull profile pictures?`,
    homeHeroDescription2: `Transform your online presence with professional-quality portraits tailored to your style – no photoshoots, no hassle.`,
    videoHeroTitle: `Unleash Your Inner Artist.`,
    videoHeroDescription: `Discover the magic of AI-generated portraits.`,
    homeForDesignerTitle: `AI Portrait Studio`,
    homeForDesignerSubtitle: `Create stunning AI-generated portraits`,
    homeForDesignerDescription: `Upload your photos and let our AI generate beautiful, unique portraits. Customize styles and download high-quality results.`,
    homeColorPresetsTitle: `Choose Your Style`,
    homeColorPresetsDescription: `100+ presets and endless customization`,
    homeColorPresetsSubDescription: `Choose from over 100 unique styles or customize your look with simple text prompts. Change hair color, add accessories, and more!`,
    homeAdvertisementTitle: `Get started with<br />  myportrait.studio today`,
    homeAdvertisementRegisterButton: `Sign In`,
    homeAdvertisementHowItWorksButton: `See How It Works`,
    welcome: `Welcome`,
    aiGeneratedPortraits: `AI-Generated Portraits`,
  },
  footer: {
    terms: `Terms of Service`,
    privacy: `Privacy Policy`,
    about: `About Us`,
    contactUs: `Contact Us`,
    faq: `FAQ`,
    imprint: `Imprint`,
    legal: `Legal`,
    contact: `Contact`,
    description: `Unleash your creativity with our AI-powered image generation. Create captivating, one-of-a-kind portraits that stand out from the crowd!`,
    professionalBusinessHeadshot: `Professional Business Headshot`,
  },
  imprint: {
    title: `Imprint`,
    heading: `Responsible for the content of this page.`,
    email: `Email`,
    address: `Address`,
    taxIdentificationNumber: `Tax Identification Number`,
    pleaseContactUs: `Please contact us via the support`,
  },
  appMenu: {
    home: `Home`,
    ratio: `Ratio`,
    generate: `Generate`,
    customize: `Customize`,
    details: `Details`,
    styles: `Styles`,
    save: `Save`,
  },
  shareEntry: {
    title: `MyPortrait Studio | Here is my picture!`,
    description: `I created this portrait with MyPortrait Studio. Check it out!`,
  },
  tinder: {
    meta: {
      title: 'AI Dating Photos That Get More Matches | Tinder Profile Pictures Generator',
      description:
        'Transform your Tinder success with AI-generated dating photos. Get 3x more matches with professional portraits. Used by 10,000+ dating app users. Try now!',
    },
    hero: {
      title: 'Get 3x More Matches With AI Dating Photos',
      subtitle: 'Look Your Best in Every Shot',
      description:
        'Tired of being overlooked? Your photos make up 95% of your dating success. Our AI creates professional, authentic portraits that show your best self and get more right swipes.',
      cta: 'Transform Your Profile Now',
      beforeLabel: 'BEFORE',
      afterLabel: 'AFTER',
      limitedOffer: '🔥 Limited Time Offer: 50% OFF',
      userCount: '10,000+ satisfied users',
    },
    statistics: {
      matchRate: {
        value: '0.1-0.3%',
        label: 'Average male match rate on Tinder with a selfie or casual photo :(',
      },
      increase: {
        value: '3x to 20x',
        label: 'Increase in matches with our professional photos (we tested it)',
      },
      firstImpression: {
        value: '94%',
        label: 'Of first impressions are based on photos. So make sure your photos are good!',
      },
    },
    psychology: {
      title: 'The Science of Attraction',
      points: {
        haloEffect: {
          title: 'The Halo Effect',
          description: 'When you look good in your first photo, people automatically assume positive things about your personality.',
        },
        patternInterruption: {
          title: 'Pattern Interruption',
          description:
            'Most Tinder photos are casual selfies. Professional-quality shots instantly break this pattern and demand attention.',
        },
        socialProof: {
          title: 'Social Proof',
          description: 'High-quality photos subcommunicate success and social status - key attractors in dating psychology.',
        },
      },
    },
    benefits: {
      title: 'Why This Works',
      items: {
        psychology: {
          title: 'The Psychology of First Impressions',
          description:
            "Research shows you have just 0.1 seconds to make a first impression. In the world of dating apps, your profile picture is everything - it\\'s the gateway to all your future connections.",
          icon: '🧠',
        },
        standOut: {
          title: 'Stand Out in a Crowded Market',
          description:
            'The average Tinder user spends only 2 seconds looking at a profile. Our AI-enhanced portraits instantly grab attention and make those seconds count.',
          icon: '⭐',
        },
        professional: {
          title: 'Professional Quality Without the Cost',
          description:
            'Professional photoshoots cost $500+. Get the same premium look for a fraction of the price, with unlimited variations.',
          icon: '💰',
        },
        optimized: {
          title: 'Scientifically Optimized',
          description:
            'Our AI is trained on millions of successful dating profiles. We know exactly what triggers right swipes and apply these insights to your photos.',
          icon: '🔬',
        },
      },
    },
    testimonials: {
      title: 'Real Results',
      subtitle: "Join thousands who've transformed their dating life",
      items: {
        first: {
          quote:
            'Ngl, I was super skeptical at first. But damn... these pics actually work 😅 Been on Tinder for months with like 2-3 matches. First week with these new pics: 6 matches and actually good ones! Worth every penny fr fr',
          name: 'Mike, 26',
          role: 'IT Developer',
        },
        second: {
          quote:
            "Ok so update after 2 weeks: Not only am I getting way more matches, but the convos are actually going somewhere?? I look 'trustworthy' and 'professional' in my new pics. Way less ghosting after 2 messages.",
          name: 'Stefan, 44',
          role: 'Accountant',
        },
        third: {
          quote:
            "Hate taking selfies and always look awkward in photos. These AI pics somehow made me look natural and confident! My matches actually doubled and I'm getting way better convos. Just wish I'd found this sooner tbh",
          name: 'Alex, 29',
          role: 'Sales Manager',
        },
      },
    },
    howItWorks: {
      title: 'How It Works',
      subtitle: 'Three simple steps to transform your dating profile',
      steps: {
        upload: {
          number: '1',
          title: 'Upload Your Best Photos',
          description:
            'Share 10-20 of your best photos. Mix it up with close-ups and full shots. The better your initial photos, the more amazing your AI portraits will be!',
        },
        train: {
          number: '2',
          title: 'AI Training',
          description:
            'Our AI learns your best features and understands what makes you uniquely you. Think of it as teaching AI your best angles and expressions.',
        },
        generate: {
          number: '3',
          title: 'Generate & Choose',
          description:
            'Create scroll-stopping portraits for your profile. Mix different styles, backgrounds, and looks until you find your perfect match-winning photos.',
        },
      },
    },
    cta: {
      title: 'Stop Settling for Average Results',
      description:
        "The longer you wait, the more matches you're missing out on. Transform your dating life today with professional AI portraits that get results.",
      button: 'Get Your AI Portraits Now',
    },
    examples: {
      title: 'Tinder Pictures that get more matches',
      description: 'These are only some examples of the portraits you can generate now.',
    },
    pricing: {
      title: 'Investment in Your Dating Success',
      subtitle: 'Choose the Perfect Plan for Your Dating Journey',
      mostPopular: 'Most Popular',
      guarantee: '100% Satisfaction Guaranteed - 30 Day Money Back',
      compareValue: 'Less than the cost of one dinner date',
    },
    trust: {
      secureCheckout: 'Secure Checkout',
      sslEncrypted: 'SSL Encrypted',
      safe: '100% Safe',
      securePayments: 'Secure Payments',
    },
    guarantee: {
      title: '100% Money-Back Guarantee',
      description:
        'Not happy with your portraits? Get a full refund within 30 days, no questions asked. We want you to be completely satisfied with your investment.',
    },
    notification: {
      recentPurchase: '{{name}} from {{location}} just purchased {{package}}',
    },
    faq: {
      title: 'Frequently Asked Questions',
      subtitle: 'Everything you need to know about our AI portrait service',
      stillHaveQuestions: "Still have questions? We're here to help!",
      contactUs: 'Contact Our Support Team',
    },
  },
  professional: {
    pricing: {
      title: `20 x less than a physical photoshoot and 5 x less than other AI headshot generators`,
      subtitle: `Full money back guarantee. Not all results turning out good? Get a full refund within 30 days, no questions asked.`,
      mostPopular: 'Most Popular',
      guarantee: '100% Satisfaction Guaranteed - 30 Day Money Back',
      compareValue: 'Less than the cost of one dinner date',
    },
    meta: {
      title: `Upgrade Your Professional Image with AI Portraits`,
      description: `Could your profile picture be holding you back? Our AI solution helps you create a professional image that opens doors and makes a lasting impression.`,
    },
    hero: {
      title: `Professional business headshots, without a physical photo shoot`,
      subtitle: `Get professional business headshots in minutes without our AI headshot generator. Upload photos, pick a style & receive 100+ headshots.`,

      cta: `Create Your Headshots`,
    },
    testimonials: {
      title: `Feedback that we love! Thank you!`,
      subtitle: `Discover how professionals are leveraging AI portraits to enhance their image`,
    },
    howItWorks: {
      title: `Create Your Professional Portrait in 3 Simple Steps`,
      subtitle: `A straightforward process designed to fit your busy schedule.`,
      steps: {
        upload: {
          number: `1`,
          title: `Upload Your Photos`,
          description: `5 minutes - Use existing photos or take new ones`,
        },
        train: {
          number: `2`,
          title: `AI Enhancement`,
          description: `1-2 hours - Our AI works its magic`,
        },
        generate: {
          number: `3`,
          title: `Download & Use`,
          description: `5 minutes - Get your professional photos`,
        },
      },
    },
    cta: {
      button: `Get Started`,
    },
    examples: {
      title: `Happy Customers`,
      subtitle: `See examples of our AI-generated professional portraits. All it takes is just a few selfies.`,
    },
    faq: {
      title: 'Frequently Asked Questions',
      subtitle: 'Find answers to common questions about our professional AI portraits',
    },
    testimonialsList: {
      customer1: {
        name: 'Mira Kavitskaya',
        role: 'Psychologist',
        comment: 'This is addictive! Not only the headshots, but also the other styles. I love it!',
      },
      customer2: {
        name: 'Stefan Teresiak',
        role: 'Founder myportrait.studio',
        comment: 'What should I say? :) I build this project and I use it for my own headshots.',
      },
      customer3: {
        name: 'Eva More',
        role: 'Model',
        comment: 'This is crazy! I can simply skip the photoshoot!',
      },
      customer4: {
        name: 'Mehdi Salehkhah',
        role: 'Financial Advisor',
        comment: 'Incredible quality and amazing results.',
      },
      customer5: {
        name: 'Marina K.',
        role: 'Business Consultant',
        comment: 'These Photos exceeded my expectations.',
      },
      customer6: {
        name: 'Leyla Verdiyeva',
        role: 'Artist',
        comment: 'Not all turned out good... but there are amazing ones!',
      },
    },
  },
  meta: {
    home: 'AI Portrait Generator | Create Professional Pictures on MyPortrait Studio',
    homedescription:
      'Transform your photos into stunning AI-generated portraits. Create professional headshots, social media pictures, and artistic portraits in minutes. Experience the future of photography with our advanced AI technology.',
    homeKeywords:
      'AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
    pricing: 'MyPortrait | Package Overview',
    pricingDescription: 'Explore our range of packages and choose the one that suits you best.',
    pricingKeywords:
      'MyPortrait, package overview, AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
    professional: 'Upgrade Your Professional Image with AI Portraits',
    professionalDescription:
      'Could your profile picture be holding you back? Our AI solution helps you create a professional image that opens doors and makes a lasting impression.',
    professionalKeywords:
      'AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
    tinder: 'AI Dating Photos That Get More Matches | Tinder Profile Pictures Generator',
    tinderDescription:
      'Transform your Tinder success with AI-generated dating photos. Get 3x more matches with professional portraits. Used by 10,000+ dating app users. Try now!',
    tinderKeywords:
      'AI portraits, Tinder profile photos, dating profile optimization, professional dating photos, profile enhancement, dating success, profile makeover, AI photography',
    faqs: 'Faqs | MyPortrait Studio',
    faqsDescription: 'Find answers to common questions about our AI portrait service.',
    faqsKeywords:
      'AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
    contact: 'Contact Us | MyPortrait Studio',
    contactDescription: 'Get in touch with us for any questions or feedback about our AI portrait service.',
    contactKeywords:
      'Contact us, AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
    privacy: 'Privacy Policy | MyPortrait Studio',
    privacyDescription: 'Learn how we handle your data and protect your privacy.',
    privacyKeywords:
      'Privacy policy, AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
    terms: 'Terms of Service | MyPortrait Studio',
    termsDescription: 'Read our terms of service and understand your rights and obligations.',
    termsKeywords:
      'Terms of service, AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos',
  },
};

export default en;
