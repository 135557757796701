import React, { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useApi } from '@/providers/ApiProvider';

interface TrackerProps {
  ids: string[];
}

declare global {
  interface Window {
    clarity?: (command: string, ...args: any[]) => void;
    gtag?: (command: string, ...args: any[]) => void;
  }
}

const BaseTracker: React.FC<TrackerProps> = ({ ids }) => {
  const router = useRouter();
  const { userApi } = useApi();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const fullReferrer = document.referrer;
      if (fullReferrer && !fullReferrer.includes('myportrait.studio')) {
        userApi?.logReferrer(fullReferrer);
      }
    }
  }, [router.asPath]);

  useEffect(() => {
    const handleRouteChange = () => {
      const currentPath = window.location.pathname + window.location.search;

      // Track route change in Google Analytics
      ids.forEach((id) => {
        if ((window as any).gtag) {
          (window as any).gtag('config', id, {
            url: currentPath,
          });
        }
      });
    };

    // Handle both regular and shallow route changes
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router, ids]);

  useEffect(() => {
    // Initialize Google Analytics
    const [gaId, gtmId] = ids;
    if (gaId && window.gtag) {
      window.gtag('config', gaId, { send_page_view: true });
    }
    if (gtmId && window.gtag) {
      window.gtag('config', gtmId, { send_page_view: true });
    }
  }, [ids]);

  return (
    <>
      {ids.map((id) => (
        <React.Fragment key={id}>
          <Script src={`https://www.googletagmanager.com/gtag/js?id=${id}`} strategy="afterInteractive" />
          <Script id={`google-analytics-${id}`} strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${id}');
            `}
          </Script>
        </React.Fragment>
      ))}

      {/* Simple Clarity Script */}
      <Script id="microsoft-clarity" strategy="afterInteractive">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "ponl8a769n");
        `}
      </Script>
    </>
  );
};

export default BaseTracker;
