import React from 'react';

const IframeComponent: React.FC = () => {
  return (
    <div style={{ position: 'relative', paddingTop: '177.77777777777777%', width: '100%' }}>
      <iframe
        src="https://customer-wpo4wdc07bb0hh2a.cloudflarestream.com/e00b5a7e0249dd766ed61a20f6c8ab85/iframe?poster=https%3A%2F%2Fcustomer-wpo4wdc07bb0hh2a.cloudflarestream.com%2Fe00b5a7e0249dd766ed61a20f6c8ab85%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
        loading="lazy"
        style={{ border: 'none', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IframeComponent;
