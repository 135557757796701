import { useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';

export const useNativePlatform = () => {
  const [isNative, setIsNative] = useState(false);

  useEffect(() => {
    setIsNative(Capacitor.isNativePlatform());
  }, []);

  return isNative;
};
