import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface CardElement {
  card: HTMLElement;
  border: HTMLElement;
}

interface MousePositionContextType {
  allCards: CardElement[];
  setAllCards: React.Dispatch<React.SetStateAction<CardElement[]>>;
  isMouseOver: boolean;
}

export const MousePositionContext = createContext<MousePositionContextType | null>(null);

export const useMousePosition = () => {
  const context = useContext(MousePositionContext);
  if (!context) {
    throw new Error('useMousePosition must be used within a MousePositionProvider');
  }
  return context;
};

interface MousePositionProviderProps {
  children: ReactNode;
}

export const MousePositionProvider: React.FC<MousePositionProviderProps> = ({ children }) => {
  const [allCards, setAllCards] = useState<CardElement[]>([]);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const handleMouseMove = (e: MouseEvent | Touch) => {
    let mouseOverAnyCard = false;
    allCards.forEach(({ card, border }) => {
      const rect = card.getBoundingClientRect();
      const localX = e.clientX - rect.left;
      const localY = e.clientY - rect.top;

      card.style.setProperty('--local-mouse-x', `${localX}px`);
      card.style.setProperty('--local-mouse-y', `${localY}px`);

      border.style.setProperty('--local-mouse-x', `${localX}px`);
      border.style.setProperty('--local-mouse-y', `${localY}px`);

      if (
        e.clientX >= rect.left &&
        e.clientX <= rect.right &&
        e.clientY >= rect.top &&
        e.clientY <= rect.bottom
      ) {
        mouseOverAnyCard = true;
      }
    });
    setIsMouseOver(mouseOverAnyCard);
  };

  useEffect(() => {
    const handleEvent = (event: MouseEvent | TouchEvent) => {
      const e = 'touches' in event ? event.touches[0] : event;
      handleMouseMove(e);
    };

    window.addEventListener('mousemove', handleEvent as EventListener);
    window.addEventListener('touchmove', handleEvent as EventListener);

    return () => {
      window.removeEventListener('mousemove', handleEvent as EventListener);
      window.removeEventListener('touchmove', handleEvent as EventListener);
    };
  }, [allCards]);

  return (
    <MousePositionContext.Provider value={{ allCards, setAllCards, isMouseOver }}>
      {children}
    </MousePositionContext.Provider>
  );
};
