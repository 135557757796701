import React, { useMemo, useState, useEffect, useContext, useCallback, createContext, ReactNode } from 'react';
import { defaultSettings } from './config-setting';
import { defaultPreset, getPresets, presetsOption } from './presets';
import localStorageAvailable from '@/utils/localStorageAvailable';
import { App as CapacitorApp } from '@capacitor/app';
import { appleLoginTokenAtom } from '@/providers/AuthProvider';
import { useSetAtom } from 'jotai';
import i18n from '@/locales/i18n';
import { useApi } from '@/providers/ApiProvider';
import { initializeLanguage } from '@/locales/initializeLanguage';
// import { initializeLanguage } from '@/locales/initializeLanguage';
// import { useApi } from '@/providers/ApiProvider';
// import i18n from '@/locales/i18n';

interface SettingsContextProps {
  themeMode: string;
  themeLayout: string;
  themeStretch: boolean;
  themeContrast: string;
  themeDirection: string;
  themeColorPresets: string;
  onToggleMode: () => void;
  onChangeMode: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onToggleDirection: () => void;
  onChangeDirection: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onChangeDirectionByLang: (lang: string) => void;
  onToggleLayout: () => void;
  onChangeLayout: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onToggleContrast: () => void;
  onChangeContrast: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onChangeColorPresets: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onToggleStretch: () => void;
  onResetSetting: () => void;
  presetsOption: typeof presetsOption;
  presetsColor: typeof defaultPreset;
}

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleMode: () => {},
  onChangeMode: () => {},
  onToggleDirection: () => {},
  onChangeDirection: () => {},
  onChangeDirectionByLang: () => {},
  onToggleLayout: () => {},
  onChangeLayout: () => {},
  onToggleContrast: () => {},
  onChangeContrast: () => {},
  onChangeColorPresets: () => {},
  presetsColor: defaultPreset,
  presetsOption: [],
  onToggleStretch: () => {},
  onResetSetting: () => {},
};

export const SettingsContext = createContext<SettingsContextProps>(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('useSettingsContext must be used inside SettingsProvider');
  return context;
};

interface SettingsProviderProps {
  children: ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
  const [themeMode, setThemeMode] = useState(defaultSettings.themeMode);
  const [themeLayout, setThemeLayout] = useState(defaultSettings.themeLayout);
  const [themeStretch, setThemeStretch] = useState(defaultSettings.themeStretch);
  const [themeContrast, setThemeContrast] = useState(defaultSettings.themeContrast);
  const [themeDirection, setThemeDirection] = useState(defaultSettings.themeDirection);
  const [themeColorPresets, setThemeColorPresets] = useState(defaultSettings.themeColorPresets);

  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  const isArabic = langStorage === 'ar';
  const isPersian = langStorage === 'fa';

  const [isI18nInitialized, setIsI18nInitialized] = useState(false);

  const { userApi } = useApi();

  const storedLang = storageAvailable ? localStorage.getItem('i18nextLng') : null;

  useEffect(() => {
    if (storageAvailable && !storedLang) {
      // Initialize with English if no language is stored
      localStorage.setItem('i18nextLng', 'en');
      initializeLanguage(userApi);
    }

    // Ensure English resources are loaded as fallback
    i18n
      .init({
        fallbackLng: 'en',
        lng: storedLang || 'en',
        resources: {
          en: require('@/locales/langs/en.ts').default, // Load English resources directly
        },
      })
      .then(() => {
        setIsI18nInitialized(true);
      })
      .catch((error) => {
        console.error('i18n initialization failed:', error);
      });
  }, []);

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar');
    } else if (isPersian) {
      onChangeDirectionByLang('fa');
    } else if (storedLang) {
      onChangeDirectionByLang(storedLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic, isPersian, storedLang]);

  useEffect(() => {
    if (storageAvailable) {
      const mode = getCookie('themeMode') || defaultSettings.themeMode;
      const layout = getCookie('themeLayout') || defaultSettings.themeLayout;
      const stretch = getCookie('themeStretch') ? JSON.parse(getCookie('themeStretch') || '{}') : defaultSettings.themeStretch;
      const direction = getCookie('themeDirection') || defaultSettings.themeDirection;
      const colorPresets = getCookie('themeColorPresets') || defaultSettings.themeColorPresets;
      const contrast = getCookie('themeContrast') || defaultSettings.themeContrast;

      setThemeMode(mode);
      setThemeLayout(layout);
      setThemeStretch(stretch);
      setThemeContrast(contrast);
      setThemeDirection(direction);
      setThemeColorPresets(colorPresets);
    }
  }, [storageAvailable]);

  // APPLE LOGIN SHIT
  const setAppleLoginToken = useSetAtom(appleLoginTokenAtom);
  useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', (data: any) => {
      // Handle the URL scheme
      if (data.url.startsWith('myportraitstudio://')) {
        const url = new URL(data.url);
        const token = url.searchParams.get('token');
        if (token) {
          // Save the token and redirect to the dashboard
          setAppleLoginToken(token);
        }
      }
    });
  }, []);

  // Mode

  const onToggleMode = useCallback(() => {
    const value = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(value);
    setCookie('themeMode', value);
  }, [themeMode]);

  const onChangeMode = useCallback((event: { target: { value: any } }) => {
    const { value } = event.target;
    setThemeMode(value);
    setCookie('themeMode', value);
  }, []);

  // Direction
  const onToggleDirection = useCallback(() => {
    const value = themeDirection === 'rtl' ? 'ltr' : 'rtl';
    setThemeDirection(value);
    setCookie('themeDirection', value);
  }, [themeDirection]);

  const onChangeDirection = useCallback((event: { target: { value: any } }) => {
    const { value } = event.target;
    setThemeDirection(value);
    setCookie('themeDirection', value);
  }, []);

  const onChangeDirectionByLang = useCallback((lang: string) => {
    const value = lang === 'ar' || lang === 'fa' ? 'rtl' : 'ltr';
    setThemeDirection(value);
    setCookie('themeDirection', value);
  }, []);

  // Layout
  const onToggleLayout = useCallback(() => {
    const value = themeLayout === 'vertical' ? 'mini' : 'vertical';
    setThemeLayout(value);
    setCookie('themeLayout', value);
  }, [themeLayout]);

  const onChangeLayout = useCallback((event: { target: { value: any } }) => {
    const { value } = event.target;
    setThemeLayout(value);
    setCookie('themeLayout', value);
  }, []);

  // Contrast
  const onToggleContrast = useCallback(() => {
    const value = themeContrast === 'default' ? 'bold' : 'default';
    setThemeContrast(value);
    setCookie('themeContrast', value);
  }, [themeContrast]);

  const onChangeContrast = useCallback((event: { target: { value: any } }) => {
    const { value } = event.target;
    setThemeContrast(value);
    setCookie('themeContrast', value);
  }, []);

  // Color
  const onChangeColorPresets = useCallback((event: { target: { value: any } }) => {
    const { value } = event.target;
    setThemeColorPresets(value);
    setCookie('themeColorPresets', value);
  }, []);

  // Stretch
  const onToggleStretch = useCallback(() => {
    const value = !themeStretch;
    setThemeStretch(value);
    setCookie('themeStretch', JSON.stringify(value));
  }, [themeStretch]);

  // Reset
  const onResetSetting = useCallback(() => {
    setThemeMode(defaultSettings.themeMode);
    setThemeLayout(defaultSettings.themeLayout);
    setThemeStretch(defaultSettings.themeStretch);
    setThemeContrast(defaultSettings.themeContrast);
    setThemeDirection(defaultSettings.themeDirection);
    setThemeColorPresets(defaultSettings.themeColorPresets);
    removeCookie('themeMode');
    removeCookie('themeLayout');
    removeCookie('themeStretch');
    removeCookie('themeContrast');
    removeCookie('themeDirection');
    removeCookie('themeColorPresets');
  }, []);

  const memoizedValue = useMemo(
    () => ({
      // Mode
      themeMode,
      onToggleMode,
      onChangeMode,
      // Direction
      themeDirection,
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      themeLayout,
      onToggleLayout,
      onChangeLayout,
      // Contrast
      themeContrast,
      onChangeContrast,
      onToggleContrast,
      // Stretch
      themeStretch,
      onToggleStretch,
      // Color
      themeColorPresets,
      onChangeColorPresets,
      presetsOption,
      presetsColor: getPresets(themeColorPresets),
      // Reset
      onResetSetting,
    }),
    [
      // Mode
      themeMode,
      onChangeMode,
      onToggleMode,
      // Color
      themeColorPresets,
      onChangeColorPresets,
      onChangeContrast,
      // Direction
      themeDirection,
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      themeLayout,
      onToggleLayout,
      onChangeLayout,
      // Contrast
      themeContrast,
      onToggleContrast,
      // Stretch
      themeStretch,
      onToggleStretch,
      // Reset
      onResetSetting,
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
};

// ----------------------------------------------------------------------

function getCookie(name: string) {
  if (typeof document === 'undefined') {
    throw new Error('getCookie() is not supported on the server. Fallback to a different value when rendering on the server.');
  }

  const value = `; ${document.cookie}`;

  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts[1].split(';').shift();
  }

  return undefined;
}

function setCookie(name: string, value: string, exdays = 3) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

function removeCookie(name: string) {
  document.cookie = `${name}=;path=/;max-age=0`;
}
