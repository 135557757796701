import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { Box } from '@mui/material';
import MainLayout from '../layouts/main';
import ScrollProgress from '../components/scroll-progress';
import { HomeHero } from '@/sections/home';
import { useLocales } from '../locales';
import CategoryTabsOberserver from '@/sections/home/CategoryTabsObserver';
import AIPortraitShowcase from '@/sections/home/AIPortraitShowcase';
import LandingPageNormal from '@/sections/home/LandingPageNormal';
import HeroSection from '@/sections/home/HeroSection';
import AIShortVideoShowcase from '@/sections/home/AIShortVideoShowcase';
import { useRouter } from 'next/router';
import AIGenerationShowcase from '@/sections/home/AIGenerationShowcase';
import Seo from '@/components/seo/Seo';
import { useNativePlatform } from 'hooks/useNativePlatform';

// Dynamically import components
const VideoHero = dynamic(() => import('@/sections/home/VideoHero'));

HomePage.getLayout = (page: React.ReactNode) => <MainLayout> {page} </MainLayout>;

function setCookie(name: string, value: string, exdays = 3) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

interface HomePageProps {
  title: string;
  description: string;
  keywords: string;
}

function HomePage() {
  const [isVisible, setIsVisible] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const { translate } = useLocales();
  const router = useRouter();
  const isNative = useNativePlatform();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (!isNative) return;

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isNative]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const welcome = router.query.welcome as string | undefined;

      if (welcome) {
        setCookie('welcome', welcome, 30);
      }
    }
  }, [router.query]);

  useEffect(() => {
    if (!isNative) return;
    if (!isVisible) {
      const welcomeTimer = setTimeout(() => {
        setShowWelcome(true);
      }, 500); // Show Welcome 0.5 seconds after initial render

      const hideWelcomeTimer = setTimeout(() => {
        setShowWelcome(false);
      }, 1500); // Hide Welcome 0.5 seconds before background starts to fade

      return () => {
        clearTimeout(welcomeTimer);
        clearTimeout(hideWelcomeTimer);
      };
    }
  }, [isVisible]);

  return (
    <>
      <Seo
        title="My Portrait Studio | Professional AI Headshots & Portraits"
        description="Transform your photos into stunning AI-generated portraits. Create professional headshots, artistic portraits, and unique profile pictures with our advanced AI technology."
        keywords="AI portrait generator, professional headshots, AI photography, portrait AI, photo transformation, digital portraits, AI headshots, professional pictures, portrait studio, AI image generation, profile pictures, LinkedIn photos"
      />
      {isNative && !isVisible && (
        <div
          style={{
            opacity: isVisible ? 0 : 1,
            transition: 'opacity 1s ease-in-out',
            backgroundColor: 'black',
            zIndex: 1200,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: '2rem',
              opacity: showWelcome ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {translate('home.welcome')}
          </div>
        </div>
      )}
      <ScrollProgress />

      <HomeHero />

      <Box
        sx={{
          boxShadow: '0px -10px 20px rgba(0, 0, 0, 0.3)',
          overflow: 'hidden',
          position: 'relative',
          bgcolor: 'background.default',
          paddingBottom: { xs: 10, md: 20 },
        }}
      >
        <AIGenerationShowcase />
        <AIShortVideoShowcase />
        <AIPortraitShowcase />
        <VideoHero />
        <LandingPageNormal />
        <CategoryTabsOberserver />
        <HeroSection />
      </Box>
    </>
  );
}

export default HomePage;
