// app/src/theme/index.tsx
import PropTypes from 'prop-types';
import { ReactNode, useMemo, useState, useEffect } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MUIThemeProvider } from '@mui/system';
// components
import { useSettingsContext } from '../components/settings';
//
import palette from './palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import { Device } from '@capacitor/device';
import React from 'react';
import { Capacitor, Plugins } from '@capacitor/core';

const { Keyboard: KeyboardPlugin } = Plugins;

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const { themeMode, themeDirection } = useSettingsContext() as {
    themeMode: 'light' | 'dark';
    themeDirection: string;
  };
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    let lastInitialScale = 0;

    const setViewport = () => {
      const height = screen.height;
      const width = screen.width;
      const portrait = height > width;
      const baseScale = 400;
      const maxScale = portrait ? 1.25 : 1;
      const minScale = 0.5;
      const initialScale = width / baseScale > maxScale ? maxScale : width / baseScale < minScale ? minScale : width / baseScale;

      // Only update if the initial scale has changed
      if (initialScale !== lastInitialScale) {
        lastInitialScale = initialScale;

        // Remove any existing viewport meta tags
        const existingViewportMeta = document.querySelectorAll('meta[name="viewport"]');
        existingViewportMeta.forEach((meta) => meta.remove());

        // Create and set a new viewport meta tag
        const newViewportMeta = document.createElement('meta');
        newViewportMeta.name = 'viewport';
        newViewportMeta.content = `viewport-fit=cover, width=device-width, initial-scale=${initialScale}, minimum-scale=0.5, maximum-scale=1.0, user-scalable=no`;
        document.head.appendChild(newViewportMeta);

        // Adjust the root element's font size
        document.documentElement.style.fontSize = `${initialScale * 100}%`;
      }
    };
    const debounce = (func: () => void, wait: number) => {
      let timeout: NodeJS.Timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
      };
    };

    const debouncedSetViewport = debounce(setViewport, 100);

    setViewport();
    window.addEventListener('resize', debouncedSetViewport);

    return () => {
      window.removeEventListener('resize', debouncedSetViewport);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const info = await Device.getInfo();
      setIsIOS(info.platform === 'ios');
    })();
  }, []);

  useEffect(() => {
    const resetViewport = () => {
      const existingViewportMeta = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
      if (existingViewportMeta) {
        existingViewportMeta.content = 'viewport-fit=cover, width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
      }
    };

    // Check if we're in a native environment and if the Keyboard plugin is available
    const isNative = Capacitor.isNativePlatform();
    if (isNative && KeyboardPlugin) {
      try {
        // Disable scroll and reset viewport when the keyboard is shown
        const handleKeyboardShow = () => {
          KeyboardPlugin.setScroll({ isDisabled: false }).catch((err: Error) => console.warn('Failed to disable scroll:', err));
          resetViewport();
        };

        // Enable scroll and reset viewport when the keyboard is hidden
        const handleKeyboardHide = () => {
          KeyboardPlugin.setScroll({ isDisabled: true }).catch((err: Error) => console.warn('Failed to enable scroll:', err));
          resetViewport();
        };

        // Add keyboard event listeners
        KeyboardPlugin.addListener('keyboardWillShow', handleKeyboardShow);
        KeyboardPlugin.addListener('keyboardWillHide', handleKeyboardHide);

        // Cleanup listeners on component unmount
        return () => {
          KeyboardPlugin.removeAllListeners();
        };
      } catch (error) {
        console.warn('Error setting up keyboard listeners:', error);
      }
    } else {
      console.debug('Keyboard plugin not available - running in browser or plugin not initialized');
    }
  }, []);

  const themeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection as 'ltr' | 'rtl',
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
      isIOS: isIOS,
    }),
    [themeDirection, themeMode]
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
