import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultLang } from './config-lang';
import enLocales from './langs/en'; // Import English translations directly

i18n.use(initReactI18next).init({
  fallbackLng: defaultLang.value,
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translations: enLocales,
    },
  },
});

const loadLanguageResources = async (lang: string) => {
  switch (lang) {
    case 'en':
      const enLocales = await import('./langs/en');
      i18n.addResourceBundle('en', 'translations', enLocales.default);
      break;
    case 'de':
      const deLocales = await import('./langs/de');
      i18n.addResourceBundle('de', 'translations', deLocales.default);
      break;
    case 'uk':
      const ukLocales = await import('./langs/uk');
      i18n.addResourceBundle('uk', 'translations', ukLocales.default);
      break;
    case 'ru':
      const ruLocales = await import('./langs/ru');
      i18n.addResourceBundle('ru', 'translations', ruLocales.default);
      break;
    case 'es':
      const esLocales = await import('./langs/es');
      i18n.addResourceBundle('es', 'translations', esLocales.default);
      break;
    case 'fr':
      const frLocales = await import('./langs/fr');
      i18n.addResourceBundle('fr', 'translations', frLocales.default);
      break;
    case 'zh':
      const zhLocales = await import('./langs/zh');
      i18n.addResourceBundle('zh', 'translations', zhLocales.default);
      break;
    case 'pl':
      const plLocales = await import('./langs/pl');
      i18n.addResourceBundle('pl', 'translations', plLocales.default);
      break;
    case 'fa':
      const faLocales = await import('./langs/fa');
      i18n.addResourceBundle('fa', 'translations', faLocales.default);
      break;
    case 'ko':
      const koLocales = await import('./langs/ko');
      i18n.addResourceBundle('ko', 'translations', koLocales.default);
      break;
    default:
      break;
  }
};

i18n.on('languageChanged', (lang) => {
  if (!i18n.hasResourceBundle(lang, 'translations')) {
    loadLanguageResources(lang);
  }
});

export const changeLanguage = async (lang: string) => {
  if (!i18n.hasResourceBundle(lang, 'translations')) {
    await loadLanguageResources(lang);
  }
  i18n.changeLanguage(lang);
};

export default i18n;
